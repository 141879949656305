import ApiService from '@/core/services/api.service';
import {
  ACT_DELETE_MY_CROP,
  ACT_GET_CLAIM,
  ACT_GET_COMMON_CODES,
  ACT_GET_CONNECT_SET,
  ACT_GET_CROP_PRICES,
  ACT_GET_CROPS,
  ACT_GET_FAQS,
  ACT_GET_FARM_ALARMS,
  ACT_GET_FARM_CONNECT,
  ACT_GET_KAMIS_DAY,
  ACT_GET_KAMIS_ITEMS,
  ACT_GET_MY_CLAIMS,
  ACT_GET_MY_CROPS,
  ACT_GET_MY_FARM,
  ACT_GET_MY_CONNECTS,
  ACT_GET_MY_FARM_CLAIMS,
  ACT_GET_MY_FARM_NOTICES,
  ACT_GET_MY_FARMS,
  ACT_GET_MY_NOTICES,
  ACT_GET_COMPARE_FARM,
  ACT_GET_COMPARE_CONNECTS,
  ACT_GET_NOTICE,
  ACT_GET_STATUS_LOGS_STATICS,
  ACT_GET_ALERT_LOGS_STATICS,
  ACT_GET_WEATHER,
  ACT_GET_WEATHER_FARM,
  ACT_INSERT_CONNECT_SET,
  ACT_INSERT_CUSTOMER,
  ACT_INSERT_MY_CLAIMS,
  ACT_INSERT_MY_CROPS,
  ACT_UPDATE_MY_CLAIMS,
  ACT_UPDATE_MY_CLAIMS_CONFIRM,
  ACT_UPDATE_MY_CROP,
  MUT_SET_FARM_DETAIL,
  MUT_SET_FARM_ID,
  RELEASE_CONNECT_ALARM,
  GET_WEEKLY_STATISTICS,
  GET_WEEKLY_COMMENTS,
  ACT_INSERT_MY_WEEKLY_COMMENT,
  ACT_DELETE_MY_WEEKLY_COMMENT,
  ACT_GET_MY_FARM_RACK,
  ACT_INSERT_MY_FARM_RACK,
  ACT_UPDATE_MY_FARM_RACK,
  ACT_DELETE_MY_FARM_RACK,
  ACT_STOP_ALARM,
  ACT_GET_NUTRIENT_SOLUTIONS,
  ACT_GET_STATUS_MEASURE,
  ACT_GET_STATUS_MEASURE_ONE,
  ACT_INSERT_STATUS_MEASURE,
  ACT_UPDATE_STATUS_MEASURE,
  ACT_DELETE_STATUS_MEASURE,
  ACT_GET_MY_FARM_YIELD_PLAN,
  ACT_INSERT_MY_FARM_YIELD_PLAN,
  ACT_UPDATE_MY_FARM_YIELD_PLAN,
  ACT_DELETE_MY_FARM_YIELD_PLAN,
  ACT_GET_MY_FARM_YIELD,
  ACT_INSERT_MY_FARM_YIELD,
  ACT_UPDATE_MY_FARM_YIELD,
  ACT_DELETE_MY_FARM_YIELD,
  ACT_GET_MY_FARM_TOTAL_YIELD,
  ACT_GET_COMPANY,
  ACT_UPDATE_COMPANY,
  ACT_GET_CONNECT_COMMENTS,
  ACT_INSERT_CONNECT_COMMENT,
  ACT_INSERT_SPECIFICITY_INFO,
  ACT_UPDATE_SPECIFICITY_INFO,
  ACT_DELETE_SPECIFICITY_INFO,
  ACT_UPDATE_MANAGER,
  ACT_GET_TASKS,
  ACT_INSERT_TASK,
  ACT_UPDATE_TASK,
  ACT_DELETE_TASK,
  ACT_GET_STATUS_LOGS,
  ACT_GET_LAST_STATUS_LOG,
  ACT_UPDATE_STATISTICS,
  ACT_FARM_TOTAL_STATICS,
  ACT_GET_APP_PUSH,
  ACT_UPDATE_APP_PUSH_ST,
  ACT_UPDATE_ALL_APP_PUSH,
  ACT_GET_FARM_THRESHOLD,
  ACT_DELETE_FILE,
  ACT_GET_CROP_IMAGE,
  ACT_INSERT_CROP_IMAGE,
  ACT_UPDATE_CROP_IMAGE,
  ACT_DELETE_CROP_IMAGE,
  ACT_GET_DEVIATION_STATUS,
  ACT_GET_NOTIFICATION_DEVIATION,
  ACT_GET_ALARM_MANAGEMENT,
  ACT_GET_NOTIFICATION_DETAIL,
  ACT_INSERT_NOTIFICATION_ACTION,
  ACT_GET_MEMBERS,
  ACT_GET_TASK_CATEGORY_LIST,
  ACT_SEND_ALARM_TEST,
  ACT_GET_RENEWAL_SET,
  ACT_UPDATE_RENEWAL_SET
} from '@/core/services/variable';
import {
  getItem,
  getPhoneFormatValue,
  getValueWithDefault,
  lengthCheck,
} from '@/core/services/funcs';

const state = {
  fno: 0,
  farmId: '',
  farmItem: null,
  farmDetail: null,
};

const getters = {
  fno(state) {
    return state.fno;
  },
  farmId(state) {
    return state.farmId;
  },
  farmNm(state){
    if(state.farmItem) return state.farmItem.farmNm;
    return '';
  },
  farmManagerPhoneNo(state){
    if(state.farmItem) return getPhoneFormatValue(state.farmItem.farmManagerPhoneNo);
    return '';
  },
  companyNm(state){
    if(state.farmItem) return getValueWithDefault(state.farmItem.companyNm);
    return '';
  },
  farmDetail(state){
    // 5분마다 만료...
    if(state.farmDetail && state.farmDetail.farmId === state.farmId && state.farmDetail.expiredDt > new Date().getTime()) return state.farmDetail;
    return null;
  }
}

const actions = {
  [ACT_GET_COMMON_CODES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/commons/codes', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARMS]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/farms', null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/farm', null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_COMPARE_FARM](context, fno) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/'+ fno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM_CLAIMS]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/farm/claim-boards', null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM_NOTICES]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/farm/notices', null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_FARM_ALARMS](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/alarms', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_CONNECT_SET]: function(context, systemId) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/connect-set/' + systemId)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_CONNECT_SET]: function(context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm/connect-set', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },

  [ACT_GET_STATUS_LOGS_STATICS]: function(context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/statuslogs/statics', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },

  [ACT_GET_ALERT_LOGS_STATICS]: function(context, params){
    const fno = params.fno;
    delete params.fno;

    return new Promise((resolve, reject) => {
      ApiService.get(`/web/v1/ai/farm-alarm-count/${fno}`, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },

  [ACT_GET_WEATHER](context, sidoGbDcd) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/weather/' + sidoGbDcd, null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_WEATHER_FARM](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/weather/farm', params)
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },

  [ACT_GET_CROP_PRICES](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/crop/prices', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_KAMIS_DAY]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/kamis/day', null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_KAMIS_ITEMS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/kamis/items', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_CONNECTS]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/farm/connects', null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_COMPARE_CONNECTS](conext, fno) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/connects/'+fno, null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_CROPS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/farm/crops', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_CROPS](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/cultivate-crops', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_MY_CROPS](context, params){
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/my/farm/crops', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MY_CROP](context, {cultivateCropSeq, params}){
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/my/farm/crop/' + cultivateCropSeq, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },

  [ACT_DELETE_MY_CROP](context, cultivateCropSeq) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/my/farm/crop/' + cultivateCropSeq, null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM_RACK](context,fno) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm-rack/list/'+ fno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_MY_FARM_RACK](context,params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm-rack/', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MY_FARM_RACK](context,rno) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm-rack/'+ rno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_MY_FARM_RACK](context,rno) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/farm-rack/'+ rno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_CLAIM](context, boardNo) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/claim-board/' + boardNo, null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_CLAIMS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/claim-boards', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_MY_CLAIMS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/my/claim-board', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MY_CLAIMS](context, {boardNo, params}){
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/my/claim-board/' + boardNo, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MY_CLAIMS_CONFIRM](context, boardNo) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/claim-board/' + boardNo +'/confirm', boardNo, null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_NOTICE](context, noticeNo){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/notice/' + noticeNo, null)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_NOTICES](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/notices', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_CUSTOMER](context, params){
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/customer', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_FAQS](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/faqs', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_FARM_CONNECT]: function(context, seq) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/connect/' + seq, {})
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [RELEASE_CONNECT_ALARM](context, params){
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm/ealarm/' + params.seq, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [GET_WEEKLY_STATISTICS]: function(context, params){
    const fno = params.fno;
    delete params.fno;

    return new Promise((resolve, reject) => {
      ApiService.get(`/web/v1/statistics/${fno}`, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [GET_WEEKLY_COMMENTS]: function(context, params){
    return new Promise((resolve, reject) => {
      ApiService.get(`/web/v1/my/farm/weekly/comment`, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_MY_WEEKLY_COMMENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/my/farm/weekly/comment', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_MY_WEEKLY_COMMENT](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/my/farm/weekly/comment/' + params.seq, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_STOP_ALARM](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/statuslog/'+params+'/stop-alarm')
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_NUTRIENT_SOLUTIONS]() {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/nutrient-solutions')
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_STATUS_MEASURE_ONE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/status-measure/one', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_STATUS_MEASURE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/my/status-measure', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_STATUS_MEASURE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/my/status-measure', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_STATUS_MEASURE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/my/status-measure', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_STATUS_MEASURE](context, sno) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/my/status-measure/'+sno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM_YIELD_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm-yield/plan', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_MY_FARM_YIELD_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm-yield/plan', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MY_FARM_YIELD_PLAN](context, {yno, params}) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm-yield/plan/'+ yno, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_MY_FARM_YIELD_PLAN](context, yno) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/farm-yield/plan/'+ yno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM_YIELD](context, yno) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm-yield/'+ yno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_MY_FARM_YIELD](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm-yield', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MY_FARM_YIELD](context, {yno, params}) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm-yield/'+ yno, params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_MY_FARM_YIELD](context, yno) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/farm-yield/'+ yno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MY_FARM_TOTAL_YIELD](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm-yield/total', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_COMPANY](context, cno) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/company/' + cno, {})
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_COMPANY](context, {cno, data}) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/company/'+ cno, data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_CONNECT_COMMENTS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/connect/comments', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_CONNECT_COMMENT](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm/connect/comment', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_SPECIFICITY_INFO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/specificity', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_SPECIFICITY_INFO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/specificity', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_SPECIFICITY_INFO](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/specificity', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_MANAGER](context, {fno, params}) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm/'+fno+'/manager', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_TASKS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm-task-list', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_TASK](context,  params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm-task', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_TASK](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm-task', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_TASK](context, tno) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/farm-task/'+tno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_STATUS_LOGS](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/influx/status-logs/field', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_LAST_STATUS_LOG](context, systemId){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/influx/status-logs/last/'+ systemId)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_STATISTICS](context, {fno, params}) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm/'+fno+'/statistics', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_FARM_TOTAL_STATICS](context, fno){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/statistics_total/' + fno)
      .then(response => resolve(response))
      .catch(error => reject(error))});
  },
  [ACT_GET_APP_PUSH](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/app-push', params)
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_APP_PUSH_ST](context, sendSeq) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/app-push/'+sendSeq+'/receive')
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_ALL_APP_PUSH]() {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/app-push/read-all')
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },
  [ACT_GET_FARM_THRESHOLD](context, fno){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/'+fno+'/env-threshold')
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_FILE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/file?key=' + params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_CROP_IMAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/my/crop-img', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_INSERT_CROP_IMAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm/my/crop-img', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_CROP_IMAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/farm/my/crop-img', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_CROP_IMAGE](context, cropImgNo) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/farm/my/crop-img/' + cropImgNo)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_DEVIATION_STATUS](context, fno) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/'+ fno +'/deviation-status')
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_NOTIFICATION_DEVIATION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/noti-system-alarm/notification-deviation', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_ALARM_MANAGEMENT](context, params){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/alarm-management', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_NOTIFICATION_DETAIL](context, seq){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/notification-deviation/'+ seq)
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },
  [ACT_INSERT_NOTIFICATION_ACTION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/farm/alarm-management', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_MEMBERS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/members', data)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_GET_TASK_CATEGORY_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm-task/category-list', params)
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },
  [ACT_SEND_ALARM_TEST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/noti-system-alarm/test', params)
          .then(response => resolve(response))
          .catch(error => reject(error));
    });
  },
  [ACT_GET_RENEWAL_SET]: function(context) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/nutrient-renewal')
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_UPDATE_RENEWAL_SET](context, {fno, systemId, params}){
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/farm/' + systemId + '/nutrient-renewal?alarm=' + params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
};


const mutations = {
  [MUT_SET_FARM_ID](state, farmId) {
    state.farmId = farmId;
    if(state.farmId){
      ApiService.get('/web/v1/my/farm/profile', null).then(response => {
        if(lengthCheck(response)){
          state.farmItem = getItem(response);
        }
      });
    }else{
      state.farmItem = null;
    }
  },
  [MUT_SET_FARM_DETAIL](state, farmDetail) {
    const expiredDate = new Date();
    expiredDate.setMinutes(expiredDate.getMinutes() + 5);
    farmDetail.expiredDt = expiredDate.getTime();

    state.farmDetail = farmDetail;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
