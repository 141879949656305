export const locale = {
    login: {
        id: '아이디',
        password: '비밀번호',
        idPlaceholder:'아이디를 입력해주세요.',
        passwordPlaceholder: '비밀번호를 입력해주세요.',
        loginText: '로그인',
        infoText1: '첫 방문이신가요?',
        infoText2: '새로운 계정을 생성하세요.',
        idNotice: '아이디를 다시 확인해 주세요.',
        passwordNotice: '입력하신 비밀번호를 다시 확인해 주세요.'
    },
    portal: {
        title: '포탈',
        placeholder: '검색어를 입력해주세요',
        code: '팜 번호 (코드)',
        cultivationType: '재배 유형',
        name: '회사',
        location: '팜 위치 (주소)',
        operationStatus: '가동상태',
        confirm: '관리 페이지로 이동',
        welcome: '님 반갑습니다.',
        updateTitle: '앱 업데이트 안내',
        updateDesc1: '티팜온 앱 최신 버전이 출시되었습니다.',
        updateDesc2: '새로운 버전으로 업데이트 후 이용해 주시기 바랍니다.',
        updateDesc3: '업데이트가 확인되지 않거나 앱 이용에 문제가 생길 경우 앱 삭제 후 재설치 부탁드립니다.',
        gotoUpdate: '업데이트 하러 가기',
    },
    topNavBar: {
        farm: {
            info: '팜 정보',
            analysis: '팜 데이터 분석',
            help: '고객센터',
            search: '팜 정보 조회',
            cropManagement : '재배작물 관리',
            yieldInfo : '생산 실적 관리',
            yieldPlanInfo : '생산 정보 관리',
            EnvMeasurementInfo: '환경 실측 관리',
            farmCalendar: '팜 일정 관리',
            farmPhotoRegistration: '작물 생육 관리',
            timeSeriesInfo: '팜 시계열 정보',
            alertLogTimeSeriesInfo: '일탈 시계열 정보',
            farmDeviationStatusInfo: '팜 일탈 현황 정보',
            weeklyreport: 'Weekly Report',
            cultivationEnvControl: '재배환경 제어',
            cultivationCropMangement: '재배작물 관리',
            setEndDate: '종료일을 시작일 이후로 설정해주시기 바랍니다.',
            threeMonth: '3개월의 기간만 조회가 가능합니다.'
        },
        claim: 'A/S',
        faq: 'FAQ',
        notice: '공지사항',
        farmPhoto: '사진 관리',
        farmTimeSeriesInfo: '시계열 분석',
        farmDetailInfo: '상세 정보',
        dashboard: '대시보드',
    },
    profile: {
        profileEdit: '프로필 수정',
        id: '아이디',
        pwd: '비밀번호',
        pwdInvalid: '비밀번호를 입력해주세요.',
        name: '이름',
        nameInvalid: '이름을 입력해주세요.',
        email: '이메일',
        emailInvalid: '이메일을 입력해주세요.',
        phoneNumber: '휴대폰 번호',
        phoneNumberInvalid: '휴대폰 번호를 입력해주세요. ( - 제외 숫자만 입력)',
        save: '저장',
        cancel: '취소',
        pwdCheck: `정보보호를 위해 비밀번호를 다시한번 입력해 주세요`,
        confirm: '확인',
        profile: '프로필',
        profileConfirm: '프로필을 수정하시겠습니까?',
        profileEditted: '프로필이 수정되었습니다.',
        error: '오류가 발생했습니다. 다시 시도 해주세요.',
        resignMembership: '회원탈퇴',
        resignSuccess: '회원탈퇴가 완료되었습니다.',
        resignConfirm: '회원탈퇴 하시겠습니까? 탈퇴하실 경우 등록된 아이디와 권한이 모두 삭제됩니다.'
    },
    dashboard: {
        title: '대시보드',
        connect: {
            '양액A(국산)': '양액A(국산)',
            '양액B': '양액B',
            '양액C': '양액C',
            '양액D': '양액D',
            '양액E(국산)': '양액E(국산)',
            '양액F(국산)': '양액F(국산)',
            '양액G(국산)': '양액G(국산)',
        },
        input: '입력',
        inputMeasauredData: '실측값 입력',
        measuredEnvironment: '측정 환경',
        controllerValue: '기기 값',
        measuredValue: '실측 값',
        currentStatus: '현재 상태',
        totalData: '데이터 수집률',
        totalOutOfRange: '전체 일탈률',
        tempOutOfRange: '온도 일탈률',
        humOutOfRange: '습도 일탈률',
        co2OutOfRange: 'CO2 일탈률',
        ecOutOfRange: 'EC 일탈률',
        phOutOfRange: 'pH 일탈률',
        diagnosisStandards: '진단 기준',
        noSystemID: 'systemId가 없어 입력이 불가합니다.',
        plzInputMeasuredValue: '실측값을 입력해주세요.',
        plzInputEnvironment: '측정 환경을 선택해주세요.',
        successMeasuredValue: '실측값이 입력되었습니다.',
        overwriteMeasuredValue: '이미 등록된 값을 덮어 씌우시겠습니까?',
        noControllerValue: '기기값이 없습니다. 등록하시겠습니까?',
        add: '추가',
        close: '닫기',
        cancel: '취소',
        ok: '확인',
        error: '오류가 발생했습니다.',
        temperature: '온도',
        waterTemperature: '수온',
        waterLevel: '양액',
        humidity : '습도',
        CO2 : 'CO₂',
        EC: 'EC 농도',
        pH: 'pH 농도',
        example: '실시간 환경정보 예시',
        registerInfo:'등록된 팜이 없습니다. 관리자를 통해 팜을 등록하여 실시간 생육상태와 기능들을 확인해주시기 바랍니다.',
        countDay: '개/일',
        company: {
            code: '팜 번호 (코드)',
            location: '팜 위치 (주소)',
            name: '회사',
            cultivationType: '재배 유형',
            cultivationArea: '재배 면적',
            cultivationCrop: '팜 재배 작물'
        },

        diagnosis: '팜 진단',
        cultivationEnvStandardAlert: {
            title: '재배환경기준 경고알림',
            ecConcentrationOver: 'EC 농도 초과',
            ecConcentrationBelow: 'EC 농도 미달',
            phConcentrationOver: 'pH 농도 초과',
            phConcentrationBelow: 'pH 농도 미달',
            co2Over: 'CO₂ 초과',
            co2Below: 'CO₂ 미달',
            highTemperature: '고온',
            lowTemperature: '저온',
            highHumidity: '습도 높음',
            lowHumidity: '습도 낮음',
            level1: '1차',
            level2: '2차',
            level3: '3차',
          },
        stapleFoodPrice: {
            title: '주요작물시세',
            weekAgoPrice: '1년전',
            yearAgoPrice: '1주전'
        },
        claimHistory: 'A/S 기록',
        farmFile: '팜 파일',
        notice: {
            notice:'공지사항',
            numbering:'순번',
            title:'제목',
            registeredDate: '일시',

        },
        weather: {
            humidity : '습도',
            wind: '바람',
            atmosphericPressure: '기압',
            minTemp: '최저',
            maxTemp: '최고'
        },
        deviationAction: {
          deviationAction: '일탈 조치',
          selectDeviationAction: '조치할 일탈 선택',
          count: '회',
          actionTime: '조치 시간',
          actionDesc: '조치 내용',
          cancel: '취소',
          actionSuccessed: '일탈 조치가 완료되었습니다.',
          plzSeclectDeviation: '조치할 일탈을 선택해주세요.',
          plzInputDesc: '조치 내용을 입력해주세요.',
          actionConfirm: '일탈을 조치하시겠습니까?\n(조치 내용은 카카오 알림으로 전송됩니다.)',
          actionFinished: '조치가 완료되었습니다.\n(조치내역은 관리자 페이지에서 확인)'
        },
        renewalAlarm: {
            off: '양액 갱신을 위해 알림을 해제하시겠습니까?',
            on: '알림을 활성화하시겠습니까?',
            renewing: '양액 갱신 중',
            editted: '알림 설정이 수정되었습니다.'
        }
    },
    farm: {
        basicInfo: '팜 기본정보',
        farmDiagnosis: '팜 진단',
        currentStatus: '현재 상태',
        totalData: '데이터 수집률',
        countDay: '개/일',
        totalOutOfRange: '전체 일탈률',
        tempOutOfRange: '온도 일탈률',
        humOutOfRange: '습도 일탈률',
        co2OutOfRange: 'CO2 일탈률',
        ecOutOfRange: 'EC 일탈률',
        phOutOfRange: 'pH 일탈률',
        diagnosisStandards: '진단 기준',
        name: '팜 이름',
        address: '팜 주소',
        province: '팜 지역',
        cultivationType: '운영방식',
        farmCode: '팜 코드번호',
        operationStatus: '가동상태',

        basicInfoA: '기본 정보 A',
        basicInfoB: '기본 정보 B',
        controllerInfo: '제어기 정보',
        cropInfo: '재배 작물 정보',
        machineInfo: '장치 정보',

        farmAddr: '팜 주소',
        farmAddrDetail: '팜 상세 주소 ',
        farmProvince: '팜 지역',
        farmStatistics: '팜 통계',
        farmInfoFile: '팜 정보 파일',
        farmManagerInfo: '팜 관리자 정보',
        yield: {
            registerPlanAndYield: '계획 및 실적 등록',
            totalRegistration: '전체 실적',
            planRegisteration: '목표 등록',
            filterValue: '필터 값:',
            x: '필터적용',
            viewAll: '전체보기',
            downloadExcel: '엑셀다운',
            noData: '등록된 생산 실적이 없습니다.',
            sowingDate: '파종일',
            seedlingDate: '육묘일',
            transplantDate: '이식일',
            plantDate: '정식일',
            harvestDate: '수확일(실제)',
            harvestDateExpected: '수확일(예상)' ,
            expected: ' (예상)',
            totalWeight: '총 중량(Kg)',
            targetYield: '목표 수확량(Kg)',
            editYieldRegistration: '실적 수정',
            yieldRegistration: '실적 등록',
            viewYield: '실적',
            headNumber: '수확 포기수',
            weightPerHead: '포기당중량(g)',
            cultivationPeriod: '재배일수',
            grade: '등급',
            issue: '이슈',
            cropNm: '작물명',
            start: '시작일',
            end: '종료일',
            nutiritionController: '제어기 명',
            filterState: '모든 기간의 전체 작물',
            addRack: '랙 추가',
            deleteRack: '랙 삭제',
            delete: '삭제',
            crop: '작물',
            controller: '제어기',
            cultivateIssue: '재배 관련 이슈',
            typeCultivateIssue: '재배 관련 이슈를 입력해주세요.',
            yearMonthDay: '년/월/일 선택',
            noPlanData: '등록된 생산 목표가 없습니다.',
            rackNm: '랙 이름',
            rackRowCount: '랙 단 수',
            rackColumnCount: '랙 열 수',
            potCount: '포트 수',
            selectRackForDelete: '삭제할 랙 선택',
            sureDelete: '재배작물을 삭제하시겠습니까?',
            cropDelete: '재배작물을 삭제하시겠습니까?',
            addRackSuccess: '재배랙이 추가 되었습니다.',
            rackLocationDuplicated: '랙 위치가 중복되었습니다.',
            enterAllValue: '모든 값을 입력해주세요.',
            successDelete: '재배작물이 삭제 되었습니다.',
            addCropSuccess: '재배작물이 추가 되었습니다.',
            rightRack: '랙 정보를 올바르게 입력해주세요.',
            usedRack: '이미 사용중인 랙 정보입니다.',
            deleteRackSuccess: '성공적으로 삭제 되었습니다.',
            realNoData: '데이터가 없습니다.',
            selectCropNm: '작물을 선택해주세요.',
            yieldManagement: '생산 실적 관리',
            farmInfo: '팜 정보',
            addPlan: '계획 추가',
            editPlan: '계획 수정',
            asOfPlantDate: '정식일 기준',
            allCrops: '전체 작물',
            asOfHarvestDate: '수확일 기준',
            rackLocation: '랙 위치',
            deleteMessage: '생산계획과 등록된 생산실적을 삭제하시겠습니까?',
            deleteYield: '생산 실적이 삭제되었습니다.',
            deleteYieldUnit: '해당 생산실적을 삭제하시겠습니까?',
            rightValue: '값을 올바르게 입력해주세요.',
            addYield: '생산 실적이 추가되었습니다.',
            editYield: '생산 실적이 수정되었습니다.',
            selectController: '제어기를 먼저 선택해주세요.',
            addYieldPlan: '생산계획이 추가되었습니다.',
            downloadYield: '해당 기간의 실적을 다운로드 하시겠습니까?',
            edit: '수정',
            currentYield: '실적/계획(%)',
            beforeSeeding: '파종일보다 빨리 수확할 수 없습니다.',
            farmName: '스마트 팜명',
            yieldData: '실적 데이터',
            excelDownloadComplete: '엑셀 다운로드 완료',
            cropD1: '재배작물(',
            cropD2: ')을 수정하시겠습니까?',
            cropD3: ')이 수정 되었습니다.',
            confirm: '확인',
        },
        photoRegistration: {
            startYmd: '시작일',
            endYmd: '종료일',
            oneMonth: '1개월',
            sixMonth: '6개월',
            oneYear: '1년',
            idx: '순번',
            cropNm: '작물 명',
            photoDate: '촬영일',
            sowingDate: '파종일',
            connectNm: '제어기 명',
            locationDetail: '상세 위치',
            photos: '사진 파일',
            desc: '설명',
            locationPlaceholder:'ex. A동 2열 3단',
            descPlaceholder:'8자 이상 작성',
            register: '사진 등록',
            noData: '등록된 사진이 없습니다.',
            reg: '등록',
            edit:'수정',
            cancel: '취소',
            delete:'삭제',
            registerPhoto: '팜 사진 등록',
            editPhoto: '팜 사진 수정',
            tableMode: '테이블 모드',
            galleryMode: '갤러리 모드',
            error: '업로드 중 오류가 발생했습니다.',
            and: '외',
            sheet: '장',
            invalidAll: '모든 정보를 입력해주세요.',
            invalidPhotos: '사진 파일을 첨부해주세요.',
            cropImgAdded: '팜 사진이 추가되었습니다.',
            cropImgEditted: '팜 사진이 수정되었습니다.',
            cropImgdeleteAsk: '팜 사진을 삭제하시겠습니까??',
            cropImgdeleted: '삭제가 완료되었습니다.',
        },
        deviationStatus: {
            noDeviation: '정상 범위',
            yesDeviation: '현재 일탈',
            haveToAction: '조치 필요(카카오톡 알림 -> 일탈 조치)',
            temperature: '온도',
            humidity: '습도',
            waterTemp: '수온',
            waterLevel: '양액 수위',
            waterPump: '양액 펌프(ab/c)',
            recieveData: '데이터 수신',
            deviationStatus: '일탈 알림 현황',
            deviationTimeseries: '일탈 시계열',
            urgentHigh: '생리장해 상한',
            urgentLow: '생리장해 하한',
            notificationLog: '일탈 알림 로그',
            date: '날짜',
            connectNm: '제어기 명',
            envType: '환경 유형',
            envValue: '환경 값',
            cancel: '취소',
            deviationAction: '일탈 조치',
            actionDate: '조치 일자',
            actionDesc: '조치 내용',
            pauseNotification: '조치 후 알림 일시정지',
            hour: '시간',
            manageYn: '조치 여부',
            memberNm: '조치자',
            sendTarget: '알림 종류',
            selectDeviationPlz: '일탈을 선택해주세요.',
            actionSuccess: '일탈 조치가 완료되었습니다.',
            plzInsertDesc: '조치 내용을 입력해주세요.',
            deviation: '일탈',
            action: '조치'
        },
        measurement: {
            measure: '실측',
            measurementInputPage: '실측 입력',
            selectDate: '날짜 선택',
            measureRegistered: '실측값이 등록되었습니다.',
            register: '등록',
            edit: '수정',
            delete: '삭제',
            temperature: '온도(°C)',
            controllerMeasured: '기기/실측',
            humidity: '습도(%)',
            waterTemperature: '수온(°C)',
            co2: 'CO2(ppm)',
            eC: 'EC',
            pH: 'pH',
            close: '닫기',
            farmInformation: '팜 정보',
            EnvManagement: '환경 실측 관리',
            controller: '제어기',
            measureYmd: '실측일',
            amPm: '오전/오후',
            temperatureControllerMeasured: '온도(°C) 기기/실측',
            humidityControllerMeasured: '습도(%) 기기/실측',
            waterTemperatureControllerMeasured: '수온(°C) 기기/실측',
            co2ControllerMeasured: 'CO2(ppm) 기기/실측',
            eCControllerMeasured: 'EC 기기/실측',
            pHControllerMeasured: 'pH 기기/실측',
            tooMuchValueDifferences: '값의 차이가 큽니다. 실측값과 기기값을 재확인 바랍니다.',
            measuredValuechanged: '실측값이 수정되었습니다',
            measuredValueDeleted: '실측값이 삭제되었습니다.',
            measuredControllerValaueDeleted: '실측, 기기값을 삭제하시겠습니까?',
            noData: '데이터가 존재하지 않습니다.',
            startYmd: '시작일',
            endYmd: '종료일',
            am: '오전',
            pm: '오후',
            invalidConnect: '제어기를 선택해주세요.',
            getCurrentValue: '현재 값 불러오기',
            connectNm: '제어기 명',
            measureDate: '실측 시간',
            alreadyEntered: '이미 입력된 데이터가 있습니다. 덮어씌우시겠습니까?',

            excelDownload: '엑셀 다운로드',
            excelDownloadConfirm: '해당 기간의 실측 데이터를 다운로드 하시겠습니까?',
            excelDownloadComplete: '엑셀 다운로드 완료',
            excelFileNm: '환경 실측 데이터.xlsx',
            tempVal: '온도(°C) 기기값',
            tempMeasure: '온도(°C) 실측값',
            humidityVal: '습도(%) 기기값',
            humidityMeasure: '습도(%) 실측값',
            waterTempVal: '수온(°C) 기기값',
            waterTempMeasure: '수온(°C) 실측값',
            co2Val: 'CO2(ppm) 기기값',
            co2Measure: 'CO2(ppm) 실측값',
            ecVal: 'EC 기기값',
            ecMeasure: 'EC 실측값',
            pHVal: 'pH 기기값',
            pHMeasure: 'pH 실측값',
            },
        infoA: {
            farmAvgProductionPDay: '일평균 생산량',
            farmAvgProductionPWeek: '주평균 생산량',
            farmAvgProductionPMonth: '월평균 생산량',
            electricAvgUsage: '월평균 전기 사용량',
            waterAvgUsage: '월평균 수도 사용량',
            co2AvgUsage: '월평균 CO₂ 사용량',
            farmManagerName: '담당자',
            farmManagerPhoneNo: '담당자 휴대폰 번호',
            farmManagerEmail: '담당자 이메일',
            won: '￦',
        },
        infoB: {
            farmScale: '팜 정보 (규모)',
            facilityInfo: '팜 시설 정보',
            farmLocation: '구역명(동)',
            farmLocationGb: '구역 구분',
            farmArea: '부지 면적',
            farmCultivateArea: '재배동 면적',
            envCtrlCount: '환경 제어기 수량',
            nutCtrlCount: '양액 제어기 수량',
            layerCount: '적용 단수',
            rowCount: '열수',
            rackCount: '랙수',
            bedCount: '베드 수',
            bedStandards: '베드 규격',
            seedRackCount: '육묘랙수',
            transplantRackCount: '이식랙수',
            lastfeedRackCount: '정식랙수',
            rackTypes: '재배랙 유형',
            potCount: '정식 포트수',
            pyeong: '평',
            units: '개',
        },
        company: {
            name: '회사이름',
            contactNumber: '회사 연락처',
            address: '주소',
            detailedAddress: '상세주소',
            findAddress: '주소 찾기',
            representative: '대표',
            date: '게시일자',
            edit: '수정',
            cancel: '취소',
            companyManagement: '회사 관리',
            editted: '의 회사정보가 수정되었습니다.',
            editQuestion: '의 회사 정보를 수정하시겠습니까?',
            companyInvalidate: '회사 명을 입력해주세요.',
            addressInvalidate: '주소를 입력해주세요.',
            dateInvalidate: '개시 날짜를 입력해주세요.'
        },

        scale: {
            title: '팜 정보(규모)',
            locationName: '구역명(A동)',
            locationClassification: '구역구분',
            siteArea: '부지면적',
            cultivationArea: '재배면적',
            envControllerCount: '환경제어기 수량',
            nutritionControllerCount: '양액제어기 수량',
            num : '개'
        },
        statistics: {
            title: '팜 통계',
            avgProductionPerDay: '일 평균 생산량',
            avgProductionPerWeek: '주 평균 생산량',
            avgProductionPerMonth: '월 평균 생산량',
            avgWaterUsagePerMonth: '월 평균 수도 사용량',
            avgElectricityUsagePerMonth: '월 평균 전기 사용량',
            avgCO2UsagePerMonth: '월 평균 CO₂ 사용량',
            won: '원',
            edit: '수정',
            cancel: '닫기',
            editMessage: '팜 통계 정보가 수정되었습니다.',
        },
        facility: {
            title: '팜 시설 정보',
            layerCount: '적용 단수',
            rowCount: '열수',
            rackType: '재배랙유형',
            bedStandard: '베드 규격',
            bedCount: '베드 수',
            rackCount: '랙 수',
            seedRackCount: '육묘 랙 수',
            transplantRackCount: '이식 랙 수',
            lastFeedRackCount: '정식 랙 수',
            portCount: '정식 포트수',
            rackLocation: '랙 위치',
        },
        manager: {
            title: '팜 관리자 정보',
            editTitle: '관리자 정보 수정',
            manager: '담당자',
            managerMobilePhone: '담당자 휴대폰 번호',
            managerEmail: '담당자 이메일',
            edit: '수정',
            cancel: '취소',
            editMessage: '관리자 정보가 수정되었습니다.'
        },
        floorPlan: '도면',
        farmResources: {
            references: '참고자료',
            document: '문서',
            video: '동영상',
            etc: '기타'
        },
        cultivationCrop: {
            title: '팜 재배작물',
            numbering: '순번',
            cropName: '작물명',
            cultivationCropType: '재배 작물 유형',
            cultivationEnvStandard: '재배 환경 기준',
            controllerConnection: '제어기 연결',
            register: '등록',
            total: '총',
            cropsCount: '개의 작물',
            noData: '등록된 재배작물이 없습니다.'
        },
        device: {
            title: '팜 장치 정보',
            numbering: '순번',
            deviceClassification: '장치구분',
            deviceType: '장치유형',
            subDeviceType: '장치항목',
            deviceName: '제품명',
            deviceStatus: '장치상태',
            controllerConnection: '제어기 연결',
            total: '총',
            deviceCount: '개의 장치'
        },
        farmDetail: {
            companyNm: '회사 이름',
            smartFarmDetail: '스마트팜 상세',
            farmName: '팜 이름',
            farmCode: '팜 코드',
            operatingState: '가동 상태',
            cultivateType: '운영 방식',
        },
        farmPopUp: {
            basicInfoA: '기본 정보 A',
            basicInfoB: '기본 정보 B',
            controllerInfo: '제어기 정보',
            cropInfo: '재배 작물 정보',
            machineInfo: '장치 정보',
          },
        // controller, setting 같이쓰고있음
        controller: {
            title: '팜 제어기 정보',
            numbering: '순번',
            controllerName: '제어기 명',
            controllerCode: '제어기 코드',
            controllerType: '제어기 유형',
            cropEnvType: '재배 환경 유형',
            culturalType: '재배 유형',
            isInternetConnected: '인터넷 연결 유무',
            systemId: '시스템 ID',
            setting: '설정',
            total: '총',
            controllerCount: '개의 장치',
            systemIdNotSet: '시스템 ID가 설정되어 있지 않습니다.'
        },
        timeseries: {
            startDate: '시작일',
            endDate: '종료일',
            whole: '통합',
            temperature: '온도',
            waterTemperature: '수온',
            humidity: '습도',
            CO2 : 'CO₂',
            EC: 'EC 농도',
            pH: 'pH 농도',
            waterLevelSolenoid: '원수 펌프',
            waterLevelMain: '양액 펌프',
            nutrientPump: '양액 펌프',
            waterLevel: '양액 수위',
            targetLine: '기준',
            minLine: '하한',
            maxLine: '상한',
            nminLine: '야간하한',
            nmaxLine: '야간상한',
            limithigh: '경보상한',
            limitlow: '경보하한',
            nlimithigh: '야간경보상한',
            nlimitlow: '야간경보하한',
            noData: '데이터가 존재하지 않습니다.',
            trendLine: '추세선',
            day: '일',
            week: '주',
            month: '월',
            downloadTimeseriesData: '해당 기간의 시계열 데이터를 다운로드 하시겠습니까?',
            logIssue: '수집 로그 이슈',
            logIssueInfo: '시계열 드래그: ZOOM, 시계열 클릭: LOG',
            timeSeries: '시계열 데이터',
            farmNm: '스마트 팜명',
            connectNm: '제어기 명',
            systemId: '시스템 ID',
            registrant: '등록자',
            registeredDate: '등록일시',
            envType: '환경 변수',
            envValue: '환경 변수 값',
            collectDate: '수집 일시',
            issue: '이슈',
            logSystemId: '시스템 ID',
            logCollectDate: '수집 일시',
            logCollectType: '수집 정보',
            logCollectData: '수집 데이터',
            comment: 'Comment',
            commentPlz: '코멘트를 입력해주세요.',
            commentSuccess: '코멘트가 등록되었습니다.',
            commentConfirm: '코멘트를 등록하시겠습니까?',
            threeMonth: '3개월 안의 데이터만 조회가 가능합니다.',
            noExcelData: "해당기간의 데이터가 없습니다.",
            excelDownloadComplete: '엑셀 다운로드 완료',
            setEndDate: '종료일은 시작일 이후로 설정해주세요.',
            register : '등록',
            cancel : '닫기',
            zeroVlaueDescription: '(0 혹은 이상경보 두배 이상의 이상치)',
            showOutlier: '이상치 표시'
        },
        alertLogTimeSeries: {
            startDate: '시작일',
            endDate: '종료일',
            day: '일',
            week: '주',
            month: '월',
            temperature: '온도',
            humidity: '습도',
            CO2 : 'CO₂',
            EC: 'EC',
            pH: 'pH',
            deviationCount: '일탈 수',
            management: '관리',
            abnormalAlarm: '이상경보',
            physiologicalDisorder: '생리장해',
            noData: '데이터가 존재하지 않습니다.',
            setEndDate: '종료일은 시작일 이후로 설정해주세요.',
            threeMonth: '3개월 안의 데이터만 조회가 가능합니다.',
            thresholdcheck: '일탈 기준 정보',
            threshold: {
                thresholdInfo: '일탈 기준 정보',
                targetValue: '목표값',
                management: '관리',
                abnormalAlarm: '이상경보',
                physiologicalDisorder:'생리장해 시작',
                maximum: '상한',
                minimum: '하한',
                day: '주간',
                night: '야간',
                close: '닫기',
                temp: '온도 (°C)',
                hum: '습도 (%)',
                seedling: '육묘',
                transplant: '이식',
                lastfeed: '정식'
            }
        },
        weeklyReport: {
            farmDataAnalysis: '팜 데이터 분석',
            weeklyAnalysis: '주간 분석',
            endDateMessage: '종료일은 시작일 이후로 설정해주세요.',
            periodDateMessage: '3개월 안의 데이터만 조회가 가능합니다.',
            cultivationMangementCommentDelete: '재배 관리 코멘트가 삭제되었습니다.',
            error: '오류가 발생했습니다. 다시 시도해주세요.',
            cultivationMangementCommentAsk: '재배관리 코멘트를 삭제하시겠습니까?',
            cultivationMangementCommentAddAsk: '재배 관리 코멘트가 등록하시겠습니까?',
            cultivationMangementCommentAdded: '재배 관리 코멘트가 등록되었습니다.',
            selectController: '제어기가 선택되지 않았습니다.',
            typecultivationMangementComment: '재배관리 코멘트를 입력해주세요.',
            farmStatusAnalysis: '팜 상태 분석',
            cultivateEnv: '재배 환경',
            diagnosticStandard: '진단 기준',
            weight: '가중치',
            farmEnvOutOfRangeStatus: '팜 환경 데이터 일탈 현황',
            standard: '기준',
            temperature: '온도',
            humidity: '습도',
            OutOfRangeNumbersPerDay: '일자별 일탈수',
            noData: '데이터가 없습니다.',
            farmInformation: '팜 정보',
            farmArea: '부지 면적',
            farmCultivateArea: '재배동 면적',
            envCtrlCount: '환경 제어기 수량',
            nutCtrlCount: '양액 제어기 수량',
            farmAvgProductionPDay: '일평균 생산량',
            waterAvgUsage: '월평균 수도 사용량',
            electricAvgUsage: '월평균 전기 사용량',
            co2AvgUsage: '월평균 CO₂ 사용량',
            layerCount: '적용단 수',
            rackCount: '랙 수',
            potCount: '정식포트 수',
            farmCultivationMangementLog: '팜 재배 관리 기록',
            cultivationMangementComment: '재배 관리 코멘트 내용',
            units: '개',
            bedCount: '베드 수',
            won: '원',
            total: '총',
            yield: '생산실적(Kg)',
            data: '데이터(%)',
            temperature2: '온도(%)',
            humidity2: '습도(%)',
            date: '일자',
            dataNumber: '데이터수',
            farmStatus: '진단',
            register: '등록',
            systemId: '시스템 ID',
            collectDate: '수집 일시',
            cancel: '닫기',
            commentEditted: '재배 관리 코멘트가 등록되었습니다.',
            commentConfirm: '재배관리 코멘트를 등록하시겠습니까?',
            connectNotSelected: '제어기가 선택되지 않았습니다.',
            commentplz: '재배관리 코멘트를 입력해주세요.',
            compare: '비교',
            thresholdcheck: '일탈 기준 정보 확인',
            threshold: {
                thresholdInfo: '일탈 기준 정보',
                targetValue: '목표값',
                management: '관리',
                abnormalAlarm: '이상경보',
                physiologicalDisorder:'생리장해 시작',
                maximum: '상한',
                minimum: '하한',
                day: '주간',
                night: '야간',
                close: '닫기',
                temp: '온도 (°C)',
                hum: '습도 (%)',
                seedling: '육묘',
                transplant: '이식',
                lastfeed: '정식'
            },
            totalWeight: '전체 일탈률 가중치'
          },
        cultivationCropMangement: {
            title: '팜 재배 작물',
            numbering: '순번',
            cropName: '작물 이름',
            cropNumber: '작물 번호 ',
            registerCrop: '재배 작물 등록',
            selectCultivationCropType: '재배 작물 유형을 선택하세요.',
            selectCultivationCrop: '재배 작물을 선택하세요.',
            cultivationCropType: '재배 작물 유형',
            cultivationEnvStandard: '재배 환경 기준',
            cropShipmentPerMonth: '월별 작물 출하량 (kg)',
            detail: '상세',
            total: '총',
            count: '개',
            register: '등록',
            save: '저장',
            remove: '삭제',
            growthPeriod: '생장 기간',
            standardWeight: '표준 포기 중량',
            monthAvgOutput: '월 목표 생산량(kg)',
            close: '닫기',
            noCropResults: '재배작물 결과가 없습니다.',
            cropAddSuccess: '재배 작물이 등록되었습니다.',
            cropAddError: '오류가 발생했습니다. 다시 시도 해주세요.',
            addCropToFarm: '선택된 재배작물을 스마트팜에 추가하시겠습니까?',
            checkCrop: '등록할 재배 작물을 선택해주세요.',
            all: '전체',


            edit: '수정',
            cancel: '닫기',
            cropNm: '작물명',
            nutrientTpye: '양액 구분',
            cultivateCropType: '재배작물 유형',
            cultivateEnvStandards: '재배 환경 기준',
            day: '일',
            titration:'적정',
            red: '적',
            bule: '청',
            green: '녹',
            white: '백',
            seed1: '사용종자#1',
            seed2: '사용종자#2',
            connect: '제어기 연결',
            informationByCropSeason: '작물 시기별 정보',
            seedling: '육묘',
            transplant: '이식',
            lastfeedDay: '정식',
            dayCount: '일수',
            temp: '온도',
            tempDay: '(온도)주간',
            tempNight: '(온도)야간',
            waterTemp: '수온',
            waterTempDay: '(수온)주간',
            waterTempNight: '(수온)야간',
            humidity: '습도',
            humidityDay: '(습도)주간',
            humidityNight: '(습도)야간',
            light: '광',
            lightIntensity: '광량',
            lightColor: '광질',
            lightTime: '일조량',

            cropD1: '재배작물(',
            cropD2: ')이 삭제 되었습니다.',
            cropD3: ')을 삭제하시겠습니까?',
            cropD4: ')이 수정 되었습니다.',
            error: '오류가 발생했습니다. 다시 시도 해주세요.',
        },
        farmSpecificityInformation: {
            farmSpecificityInformation: '팜 특성정보',
            register: '등록',
            numbering: '순번',
            specificy: '특성',
            description: '설명',
            tag: '태그',
            edit: '수정',
            delete: '삭제',
            cancel: '취소',
            tagPlaceholder: '태그를 입력한 후 엔터를 눌러주세요',
            specificityPlaceholder: '특성 정보를 입력하여 주세요',
            deleted: '해당 특성정보가 삭제되었습니다.',
            deleteQuestion: "해당 특성정보를 삭제하시겠습니까?",
            invalidvalue: "값을 올바르게 입력해주세요.",
            saved: '특성 정보가 저장되었습니다.',
            edited: '특성 정보가 수정되었습니다.'
        },
        farmCalendar:{
            taskRegister: '일정 등록',
            taskEdit: '일정 수정',
            category: '카테고리',
            taskDesc: '일정 내용',
            register: '등록',
            edit: '수정',
            cancel: '닫기',
            delete: '삭제',
            nutrientSolution: '양액',
            airConditioner: '공조기',
            electricity: '전기',
            etc: '기타',
            invalidDesc: "일정 내용을 입력해주세요.",
            invalidDate: "일정의 시작일과 종료일을 확인해주세요.",
            registered: '일정이 등록되었습니다.',
            editted: '일정이 수정되었습니다.',
            deleted: '일정이 삭제되었습니다.',
            deleteAsk: '해당 일정을 삭제하시겠습니까?',

            author: '작성자',
            taskStartDate: '일정 시작일',
            taskEndDate: '일정 종료일',
            connectNm: '제어기'
        }
    },
    claim: {
        all: '전체',
        requested: '요청',
        inProgress: '처리중',
        completed: '처리완료',
        confirmed: '확인완료',
        numbering: '순번',
        registrant: '등록자',
        type: '유형',
        claim: 'A/S',
        registeredDate: '등록 일시',
        progressStatus: '처리 상태',
        register: '등록',
        look: '보기',
        edit: '수정',
        noData: '등록된 요청이 없습니다',
        registerClaim: {
            registrant: '등록자',
            contact: '등록자 연락처',
            email: '등록자 이메일',
            corporateName: '회사 이름',
            farmName: '팜이름',
            type: 'A/S 유형',
            typePlaceholder: '요청 유형을 선택해주세요.',
            'SW':'SW',
            '설비': '설비',
            '재배': '제배',
            '기타': '기타',
            title: 'A/S 제목',
            titlePlaceholder: '요청 제목',
            content: 'A/S 상세',
            contentPlaceholder: '요청 상세',
            attachment: '첨부 파일',
            attachmentPlaceholder: '첨부파일을 등록해주세요.',
            add: '추가',
            register: '등록',
            edit: '수정',
            back: '뒤로가기',
            error: '오류가 발생했습니다. 다시 시도해주세요.',

            fileConfirm: '첨부 파일은 최대 5개까지 첨부가능합니다.',
            claimAdded: '클레임이 등록되었습니다.',
            claimConfirm: '클레임을 등록하시겠습니까?',
            claimEditted: '클레임이 수정되었습니다.',
            claimEditConfirm: '클레임을 수정하시겠습니까?',
            requestTitle: '요청 제목을 입력해주세요.',
            requestDesc: '요청 내용을 입력해주세요.',
            claimConfirmed: '클레임 확인 처리했습니다.',
            claimConfirmAsk: '클레임을 확인 처리하시겠습니까?',
            confirm: '확인',
        },
        detail: {
            whoIsInChargeOfProcess: '처리 담당자',
            whoProcessedTheClaim: '처리 완료자',
            processContent: '처리 상세',
            processedDate: '처리 일시',
            look: '보기'
        }
    },
    faq: {
        search: '검색',
        inquiry: '문의하기',
        searchPlaceholder: '자주묻는 질문을 검색해 보세요',
        customerService: '고객 상담',
        partnershipInquiry: '제휴 문의',
        serviceIncidentInquiry: '장애 문의',
        consultationRequest: '상담 요청',
        registrant: '등록자',
        registeredDate: '등록 일시',
        coporateName: '회사 이름',
        farmName: '팜 이름',
        inquiryContent: '문의 내용',
        inquiryContentPlaceholder: '문의 내용을 입력해주세요.',
        inquiryAsk: '해당 내용으로 문의 하시겠습니까?',
        send: '보내기',
        useQuickFaq: '자주하는 질문을 이용하시면, 궁금증을 빠르게 해결하실 수 있어요.',
        inquiryrequest: '문의 사항을 요청했습니다.',
        error: '오류가 발생했습니다. 다시 시도해주세요.',
    },
    notice: {
        numbering: '순번',
        type: '공지 유형',
        title: '제목',
        registrant: '등록자',
        registeredDate: '등록일시',
        noData: '등록된 공지사항이 없습니다.',
        look: '보기',
    },
    noticeView: {
        noticeDetail: '공지사항 상세 보기',
        registrant: '등록자',
        registeredDate: '등록일시',
        title: '제목',
        detail: '상세',
        back: '뒤로가기',
    },
    cultivationEnvControlSetting: {
        target: '목표치',
        targetDay: '주간',
        targetNight: '야간',
        temperature: '온도',
        humidity: '습도',
        CO2: 'CO₂',
        pH: 'pH농도',
        EC: 'EC농도',
        light: '광량(Light)',
        uv: 'UV램프',
        fan: '소형팬(Fan)',
        airfan: '써큘레이터(Airfan)',
        pump: '펌프(Pump)',
        management: '관리',
        abnormalalert: '이상경보',
        diff: '편차',
        min: '최소',
        max: '최대',
        limitHigh: '최대알림경게',
        limitLow: '최소알림경계',
        operationTime: '지속시간',
        interval: '동작간격',
        duration: '동작시간',
        startTime: '시작시간',
        endTime: '종료시간',
        repeatCount: '반복횟수',
        noTimeSelected: '선택된 시간이 없습니다',
        power: '전원',
        maintenenceTime: '정비시간',
        alwaysPerHour: '항상',
        halfAnHourPerHour: '30분간격',
        edit: '수정',
        close: '닫기',
        maxDay: '상한 (Day)',
        minNight: '하한 (Day)',
        min2: '하한',
        max2: '상한',
        hour: '시간',
        settingEditted: '의 제어기 설정이 수정되었습니다.',
        settingEditConfirm: '의 설정을 수정하시겠습니까?',
        time: ['시간 1', '시간 2', '시간 3', '시간 4', '시간 5', '시간 6']
    },
    dropdownNotification:{
        farmAlarm: '팜 알림',
        totalAlarm: '팜 일탈 알림',
        unreadAlarm: '읽지 않은 알림',
        noAlarm: '알림이 없습니다.',
        noUnreadAlarm: '읽지 않은 알림이 없습니다.',
        gotoDeviationStatus: '일탈 현황 확인',
        readAll: '모두 읽음 표시',
        more: '더보기',
        loading: '로딩중',
        refresh: '새로고침'
    }
};
