import Vue from "vue";
import axios from "axios";
import farm from "./store/farm.module";
import auth from "./store/auth.module";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  apiUrl() {

    if(document.location.host === "localhost:8081"){
      return "http://localhost:8080";
    }

    const _temp_hostName = document.location.host.split(".");

    if(_temp_hostName[1] === 'global'){
      return document.location.protocol + '//apis.' + _temp_hostName[2] + '.' + _temp_hostName[3]
    }

    return document.location.protocol + '//apis.' +  _temp_hostName[1] + '.' + _temp_hostName[2]

    // return "https://apis.t-farmon.com";
    // return 'https://apis.tfarmon.com';
    // return 'http://apis.farmbook.com:8080';
  },
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = this.apiUrl();

    Vue.axios.interceptors.request.use(
      function(request) {
        const headers = request.headers;
        if (JwtService.getToken())
          headers["Farm-Auth-Token"] = JwtService.getToken();
        if (JwtService.getRefreshToken())
          headers["Farm-Refresh-Token"] = JwtService.getRefreshToken();
        headers["Farm-Device-UUID"] = JwtService.getDeviceUuid();
        headers["Farm-Id"] = farm.state.farmId;
        headers["Api-Key"] = auth.state.apikey;
        request.headers = headers;
        return request;
      },
      function(error) {
        return Promise.reject(error);
      }
    );

    Vue.axios.interceptors.response.use(
      function(response) {
        if (response && response.data && response.data.status) {
          response.data.data.status = response.data.status;
          return response.data.data;
        } else {
          return response;
        }
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  // get(resource, slug = "") {
  //   return Vue.axios.get(`${resource}/${slug}`).catch(error => {
  //     // console.log(error);
  //     throw new Error(`[KT] ApiService ${error}`);
  //   });
  // },
  get(resource, params) {
    return Vue.axios.get(`${resource}`, { params: params }).catch((error) => {
      // console.log(error);
      throw new Error(`[TFarmOn] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, params) {
    return Vue.axios.delete(`${resource}`, { data: params }).catch((error) => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
