import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: 'signin',
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "signin",
          path: "/signin",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "emailConfirm",
          path: "/email-confirm",
          component: () => import("@/view/pages/auth/EmailConfirm")
        },
        {
          name: "pwdReset",
          path: "/pwd-reset",
          component: () => import("@/view/pages/auth/PwdReset")
        },
      ]
    },
    {
      path: "/",
      redirect: 'privacy',
      component: () => import("@/view/layout/LayoutNoauth"),
      children: [
        {
          name: "privacy",
          path: "/privacy",
          component: () => import("@/view/pages/Privacy")
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/popup",
          name: "popup",
          component: () => import("@/view/pages/Popup.vue")
        }
      ]
    },
    {
      path: "/",
      // redirect: 'portal',
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "portal",
          path: "/portal",
          component: () => import("@/view/pages/Portal.vue")
        },
        {
          name: "Profile",
          path: "/profile",
          component: () => import("@/view/pages/user/Profile")
        },
        {
          path: "/smartfarm/:farmId",
          name: "Farm",
          component: () => import("@/view/pages/farm/Farm.vue"),
          children: [
            {
              path: 'dashboard',
              name: 'Dashboard',
              component: () => import("@/view/pages/farm/FarmDashboard.vue"),
              children: [
                {
                  name: "dashboardWithHahsedSeq",
                  path: ":hashedSeq",
                  component: () => import("@/view/pages/farm/FarmDashboard.vue")
                }
              ]
            },
            {
              path: 'detail',
              name: "Detail",
              component: () => import("@/view/pages/farm/FarmDetail.vue")
            },
            {
              path: 'yield-plan-information',
              name: "YieldPlanInformation",
              component: () => import("@/view/pages/farm/YieldPlanInformation.vue")
            },
            {
              path: 'environment-measurement-information',
              name: "EnvironmentMeasurementInformation",
              component: () => import("@/view/pages/farm/EnvironmentMeasurementInformation.vue")
            },
            {
              path: 'time-series',
              name: "TimeSeries",
              component: () => import("@/view/pages/farm/FarmTimeSeries.vue")
            },
            {
              path: 'alert-log-time-series',
              name: "AlertLogTimeSeries",
              component: () => import("@/view/pages/farm/AlertLogTimeSeries.vue")
            },
            {
              path: 'farm-photo-registration',
              name: "FarmPhotoRegistration",
              component: () => import("@/view/pages/farm/FarmPhotoRegistration.vue")
            },
            {
              path: 'farm-calendar',
              name: "FarmCalendar",
              component: () => import("@/view/pages/farm/FarmCalendar.vue")
            },
            {
              path: 'weekly-report',
              name: "WeeklyReport",
              component: () => import("@/view/pages/farm/WeeklyReport.vue")
            },
            {
              path: "setting",
              name: "Setting",
              component: () => import("@/view/pages/farm/FarmSetting.vue")
            },
            {
              path: "crops",
              name: "Crops",
              component: () => import("@/view/pages/farm/FarmCrops.vue")
            },
            {
              path: "as",
              name: "As",
              component: () => import("@/view/pages/As.vue")
            },
            {
              path: "as/:boardNo",
              name: "AsEdit",
              component: () => import("@/view/pages/AsEdit.vue")
            },
            {
              path: "as-view/:boardNo",
              name: "ASView",
              component: () => import("@/view/pages/AsView.vue")
            },
            {
              path: "customer",
              name: "Customer",
              component: () => import("@/view/pages/Customer.vue")
            },
            {
              path: "faq",
              name: "Faq",
              component: () => import("@/view/pages/Faq.vue")
            },
            {
              path: "notice",
              name: "Notice",
              component: () => import("@/view/pages/Notice.vue")
            },
            {
              path: "notice/:noticeNo",
              name: "NoticeView",
              component: () => import("@/view/pages/NoticeView.vue")
            },
            {
              path: ":rootAlarmSeq/notification-action",
              name: "DeviationStatus",
              component: () => import("@/view/pages/farm/DeviationStatus.vue")
            },
            {
              path: "notification-action",
              name: "DeviationStatus",
              component: () => import("@/view/pages/farm/DeviationStatus.vue")
            }
          ]
        },
        {
          path: "/popup",
          name: "popup",
          component: () => import("@/view/pages/Popup.vue")
        }
      ]
    },
    // {
    //   path: "/error",
    //   name: "error",
    //   component: () => import("@/view/pages/error/Error.vue"),
    //   children: [
    //     {
    //       path: "error-1",
    //       name: "error-1",
    //       component: () => import("@/view/pages/error/Error-1.vue")
    //     },
    //     {
    //       path: "error-browser",
    //       name: "error-browser",
    //       component: () => import("@/view/pages/error/ErrorBrowser.vue")
    //     },
    //     {
    //       path: "error-3",
    //       name: "error-3",
    //       component: () => import("@/view/pages/error/Error-3.vue")
    //     },
    //     {
    //       path: "error-4",
    //       name: "error-4",
    //       component: () => import("@/view/pages/error/Error-4.vue")
    //     },
    //     {
    //       path: "error-5",
    //       name: "error-5",
    //       component: () => import("@/view/pages/error/Error-5.vue")
    //     },
    //     {
    //       path: "error-6",
    //       name: "error-6",
    //       component: () => import("@/view/pages/error/Error-6.vue")
    //     }
    //   ]
    // },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-404.vue")
    }
  ]
});