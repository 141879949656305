<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "assets/plugins/s-core/font/S-CoreDream.css";

// Main demo style scss
@import "assets/sass/style.vue";
// Custom css
@import "assets/css/common-custom.css";
</style>

<script>
import {OVERRIDE_LAYOUT_CONFIG} from '@/core/services/store/config.module';
import {REGISTER_DEVICE_TOKEN, SET_APIKEY, SET_DEVICE, SET_PUSH_ITEM} from '@/core/services/store/auth.module';
import {ANDROID_API_KEY, IOS_API_KEY} from '@/core/services/variable';

export default {
  name: "MetronicVue",
  created() {
    document.addEventListener(
    typeof cordova !== 'undefined' ? 'deviceready' : 'DOMContentLoaded',
    () => {
      if (window.device) {
        const device = window.device;
        this.$store.commit(SET_DEVICE, device);
        const platform = device.platform.toLowerCase();

        if(platform === 'ios') this.initIOS();
        else if(platform === 'android') this.initAndroid();

        if(window.PushNotification) this.initNotification()
      }
    });
  },

  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  methods: {
    initNotification () {
      const options = {
        android: {
          // icon:'icon',
          // icon:'notification_icon',
          // iconColor:'#2a2c36'
        },
        ios: {
          alert: "true",
          badge: "true",
          sound: "true"
        },
        windows: {}
      }
      options.android.topics = ["T-FarmON-Notification"];
      options.ios.topics = ["T-FarmON-Notification"];

      const push = window.PushNotification.init(options)
      this.$store.commit(SET_PUSH_ITEM, push);

      push.on('registration', data => {
        this.$store.dispatch(REGISTER_DEVICE_TOKEN, {
          pushId: data.registrationId,
          pushType: data.registrationType
        })
        .then(() => {})
        .catch(e => {console.error(e)});
      })

      push.on('error', function(e) {
        console.error('push error = ' + e.message);
      })

      push.on('notification', data => {
        console.log(data);
      })
    },
    initIOS () {
      this.$store.commit(SET_APIKEY, IOS_API_KEY);
    },
    initAndroid () {
      this.$store.commit(SET_APIKEY, ANDROID_API_KEY);
    },
  }
};
</script>
