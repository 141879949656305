import Vue from "vue";
// import VueI18n from "vue-i18n";
import i18n from '@/core/plugins/vue-i18n.js';

export const ACT_ADD_ALERT = 'addAlert'
export const ACT_SHOW_ERROR_ALERT = 'showErrorAlert'
export const ACT_SHOW_CONFIRM_ALERT = 'showConfirmAlert'
export const ACT_SHOW_CHECK_ALERT = 'showCheckAlert'
export const MUT_CREATE_ALERT = 'createAlert'


const state = {
  alert: {}
}

const getters = {
  alert(state) {
    return state.alert;
  }
}

const actions = {
  [ACT_ADD_ALERT] ({ commit }, alertOptions) {
    if (!alertOptions.show) alertOptions.show = 3;
    if (!alertOptions.color) alertOptions.color = 'danger';
    commit(MUT_CREATE_ALERT, alertOptions)
  },
  [ACT_SHOW_ERROR_ALERT](context, message) {
    const info = {
      title: (message ? message : `${i18n.t('dashboard.error')}`),
      icon: 'error',
      confirmButtonText: `${i18n.t('dashboard.ok')}`,
      confirmButtonColor: '#131628'
    }
    Vue.swal.fire(info)
  },
  [ACT_SHOW_CONFIRM_ALERT](context, { info, callback, cancelCallback }) {
    info.icon = 'info'
    info.reverseButtons = true
    info.focusCancel = true
    info.confirmButtonColor = '#131628'
    info.confirmButtonText = `${i18n.t('dashboard.ok')}`
    info.cancelButtonText = `${i18n.t('dashboard.cancel')}`
    info.showCancelButton = true
    Vue.swal.fire(info).then(result => {
      if (result.value) {
        if(typeof callback === 'function') callback()
      }else if (result.dismiss === 'cancel' || result.dismiss === 'esc') {
        if(typeof(cancelCallback) === 'function') cancelCallback();
      }
    })
  },
  [ACT_SHOW_CHECK_ALERT](context, { info, icon }) {
    info.icon = icon
    info.reverseButtons = true
    info.confirmButtonColor = '#131628'
    info.confirmButtonText = `${i18n.t('dashboard.ok')}`
    Vue.swal.fire(info)
  }
};

const mutations = {
  [MUT_CREATE_ALERT] (state, alert) {
    state.alert = alert;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
