// USA
export const locale = {
  login: {
    id: 'ID',
    password: 'Password',
    idPlaceholder:'Please enter your ID',
    passwordPlaceholder: 'Please enter your Password',
    loginText: 'Login',
    infoText1: 'Is This Your First Visit?',
    infoText2: 'Create a new account',
    idNotice: 'Please check your ID',
    passwordNotice: 'Please check your Password'
},
portal: {
    title: 'Portal',
    placeholder: 'Please enter your keyword(s) to search.',
    code: 'Code',
    cultivationType: 'Cultivation Type',
    name: 'Name',
    location: 'Location',
    operationStatus: 'Operation Status',
    confirm: 'Go to dashboard',
    welcome:'Welcome!',
    updateTitle: 'App update guide',
    updateDesc1: 'We have prepared the latest version of the better T-Farm On app.',
    updateDesc2: 'Please use it after updating to the new version.',
    updateDesc3: 'If the update is not confirmed or there is a problem using the app, please delete the app and reinstall it.',
    gotoUpdate: 'Go to update',
},
topNavBar: {
  farm: {
      info: 'Farm Info',
      analysis: 'Farm Data Analysis',
      help: 'Customer Service',
      search: 'Farm Info',
      cropManagement : 'Crop Mangement',
      yieldInfo : 'Yield Management',
      yieldPlanInfo : 'Yield Management',
      EnvMeasurementInfo: 'Env. Management',
      farmCalendar: 'Farm Calendar',
      farmPhotoRegistration: 'Growth Management',
      timeSeriesInfo: 'Farm Timeseries Info',
      alertLogTimeSeriesInfo: 'Alert Log Info',
      farmDeviationStatusInfo: 'Farm Deviation Status',
      weeklyreport: 'Weekly Report',
      cultivationEnvControl: 'Env. Control',
      cultivationCropMangement: 'Crop Management',
      setEndDate: 'Please set the end date after the start date.',
      threeMonth: 'Only data within 3 months can be viewed.'
  },
    claim: 'A/S',
    faq: 'FAQ',
    notice: 'Notice',
    farmPhoto: 'Farm Photo',
    farmTimeSeriesInfo: 'Timeseries',
    farmDetailInfo: 'Detail Info',
    dashboard: 'Dashboard',
  },
  profile: {
    profileEdit: 'Profile Edit',
    id: 'ID',
    pwd: 'Password',
    pwdInvalid: 'Please enter a Password.',
    name: 'Name',
    nameInvalid: 'Please enter a ID.',
    email: 'Email',
    emailInvalid: 'Please enter a Email.',
    phoneNumber: 'Phone Number',
    phoneNumberInvalid: 'Please enter your phone number. (Enter only the digits)',
    save: 'Save',
    cancel: 'Cancel',
    pwdCheck: `Please re-enter your password to protect your information.`,
    confirm: 'confirm',
    profile: 'Profile',
    profileConfirm: 'Are you sure you want to edit your profile?',
    profileEditted: 'Your profile has been editted.',
    error: 'Error Has Been Occured, Please Try Again',
    resignMembership: 'Resign membership',
    resignSuccess: 'Resign membership successed',
    resignConfirm: 'Do you want to resign membership? If you resign your membership, all registered IDs and privileges will be deleted.'
  },
  dashboard: {
    title: 'Dashboard',
    connect: {
      'NutrientSolusionA': 'NutrientSolusionA',
      'NutrientSolusionB': 'NutrientSolusionB',
      'NutrientSolusionC': 'NutrientSolusionC',
      'NutrientSolusionD': 'NutrientSolusionD',
      'NutrientSolusionE': 'NutrientSolusionE',
      'NutrientSolusionF': 'NutrientSolusionF',
      'NutrientSolusionG': 'NutrientSolusionG',
     },
    input: ' ',
    inputMeasauredData: 'Input Measured Value',
    measuredEnvironment: 'Environment',
    controllerValue: 'Controller Value',
    measuredValue: 'Measured Value',
    currentStatus: 'Current Status',
    totalData: 'Total Data',
    totalOutOfRange: 'Total Out Range',
    tempOutOfRange: 'Temperature Out Range',
    humOutOfRange: 'Humidity Out Range',
    co2OutOfRange: 'CO2 Out Range',
    ecOutOfRange: 'EC Out Range',
    phOutOfRange: 'pH Out Range',
    diagnosisStandards: 'Diagnosis Standards',
    noSystemID: 'Sytem ID Does Not Exist, Cannot Enter Value.',
    plzInputMeasuredValue: 'Please Enter Measured Value.',
    plzInputEnvironment: 'Please Select Environment.',
    successMeasuredValue: 'Measure Value Has Been Entered Succesfully.',
    overwriteMeasuredValue: 'Are You Sure To Overwrite Measured Value?',
    noControllerValue: 'Controller Value Does Not Exist, Are You Sure to Register?',
    add: 'Add',
    close: 'Close',
    cancel: 'Cancel',
    ok: 'OK',
    error: 'An Error Has Occurred.',
    temperature: 'Temp.',
    waterTemperature: 'W.Temp.',
    waterLevel: 'W.Level',
    humidity : 'Humidity',
    CO2 : 'CO₂',
    EC: 'EC',
    pH: 'pH',
    example: 'Example of real-time environmental information',
    registerInfo:'There are no registered farms. Register the farm through the administrator and check the real-time growth status and functions.',
    countDay: 'Data/Day',
    company: {
      code: 'Code',
      location: 'Location',
      name: 'Name',
      cultivationType: 'Cultivation Type',
      cultivationArea: 'Cultivation Area',
      cultivationCrop: 'Cultivation Crop'
    },
    diagnosis: 'Farm Diagnosis',
    cultivationEnvStandardAlert: {
      title: 'Cultivation Env. Standard Alert',
      ecConcentrationOver: 'EC Exceed Standard',
      ecConcentrationBelow: 'EC Below Standard',
      phConcentrationOver: 'pH Exceed Standard',
      phConcentrationBelow: 'pH Below Standard',
      co2Over: 'CO₂ Exceed Standard',
      co2Below: 'CO₂ Below Standard',
      highTemperature: 'High Temperature',
      lowTemperature: 'Low Temperature',
      highHumidity: 'High Humidity',
      lowHumidity: 'Low Humidity',
      level1: '1st',
      level2: '2nd',
      level3: '3rd',
    },
    stapleFoodPrice: {
      title: 'StapleFoodPrice',
      yearAgoPrice: '1Year ago',
      weekAgoPrice: '1Week ago'
    },
      claimHistory: 'A/S History',
      farmFile: 'Farm File',
      notice: {
        notice:'Notice',
        numbering:'No',
        title:'Title',
        registeredDate: 'Date',

    },
    weather: {
      humidity : 'Humidity',
      wind: 'Wind',
      atmosphericPressure: 'Pressure',
      minTemp: 'Low',
      maxTemp: 'High'
    },
    deviationAction: {
      deviationAction: 'Deviation Action',
      selectDeviationAction: 'Select Deviation',
      count: 'Time',
      actionTime: 'Action Time',
      actionDesc: 'Action Description',
      cancel: 'Cancel',
      actionSuccessed: 'Deviation action completed.',
      plzSeclectDeviation: 'Please select the deviation to be acted upon.',
      plzInputDesc: 'Please enter action details.',
      actionConfirm: 'Do you want to take action on the deviation?\n(The action will be sent as a Kakao notification.)',
      actionFinished: 'The action has been completed.\n(Check the action details on the admin page)'
    },
    renewalAlarm: {
      off: 'Do you want to turn off the notification?',
      on: 'Do you want to turn on the notification?',
      renewing: 'Renewing',
      editted: 'Notification settings have been modified'
   }
  },
  farm: {
    basicInfo: 'Farm Basic Info',
    farmDiagnosis: 'Farm Diagnosis',
    currentStatus: 'Current Farm Status',
    totalData: 'Total Data',
    countDay: 'Data/Day',
    totalOutOfRange: 'Total Out Range',
    tempOutOfRange: 'Temperature Out Range',
    humOutOfRange: 'Humidity Out Range',
    co2OutOfRange: 'CO2 Out Range',
    ecOutOfRange: 'EC Out Range',
    phOutOfRange: 'pH Out Range',
    diagnosisStandards: 'Diagnosis Standards',
    name: 'Farm Name',
    address: 'Farm Address',
    province: 'Farm Province',
    cultivationType: 'Cultivation Type',
    farmCode: 'Farm Code',
    operationStatus: 'Operation Status',
    basicInfoA: 'Basic Information A',
    basicInfoB: 'Basic Information B',
    controllerInfo: 'Controller Information',
    cropInfo: 'Crop Information',
    machineInfo: 'Machine Information',
    farmAddr: 'Farm Address',
    farmAddrDetail: 'Farm Address Detail',
    farmProvince: 'Farm Province',
    farmStatistics: 'Farm Statistics',
    farmInfoFile: 'Farm Information File',
    farmManagerInfo: 'Farm Manger Information',
    yield: {
      registerPlanAndYield: 'Register Plan and Yield',
      totalRegistration: 'Total Registraion',
      planRegisteration: 'Plan Registration',
      filterValue: 'Filter Value:',
      applyFilter: 'Apply Filter',
      viewAll: 'View All',
      downloadExcel: 'Download Excel',
      sowingDate: 'Sowing Date',
      seedlingDate: 'Seedling Date',
      transplantDate: 'Transplant Date',
      plantDate: 'Plant Date',
      harvestDate: 'Harvest Date(Actual value)' ,
      harvestDateExpected: 'Harvest Date(Expected)' ,
      expected: '(Expected)',
      totalWeight: 'Total Weight(Kg)',
      targetYield: 'Yield Plan(Kg)',
      editYieldRegistration: 'Edit Yield Registration',
      yieldRegistration: 'Yield Registration',
      viewYield: 'Detail',
      headNumber: 'Head Number',
      weightPerHead: 'Weight Per Head(g)',
      cultivationPeriod: 'Cultivation Periods',
      grade: 'Grade',
      issue: 'Issue',
      crop: 'Crop',
      start: 'Start',
      end: 'End',
      nutiritionController: 'Nutrition Controller',
      filterState: 'Every crops in all pereiods',
      addRack: 'Add Rack',
      deleteRack: 'Delete Rack',
      controller: 'Controller',
      cultivateIssue: 'Issue Notes',
      cropNm: 'Crop Name',
      delete: 'Delete',
      typeCultivateIssue: 'Type Issues on Cultivating',
      yearMonthDay: 'Year/Month/Day',
      noData: 'No Yield Data Available',
      noPlanData: 'No Yield Plan Data Available',
      rackNm: 'Rack Name',
      rackRowCount: 'Rack Tier',
      rackColumnCount: 'Rack Column',
      potCount: 'Pots',
      selectRackForDelete: 'Select Rack for Delete',
      sureDelete: 'Are You Sure to Delete?',
      cropDelete: 'Are you Sure to Delete?',
      addRackSuccess: 'Rack has been Added Successfully',
      rackLocationDuplicated: 'Rack location has been duplicated',
      enterAllValue: 'Please Enter All of Values',
      successDelete: 'Successfully Deleted',
      addCropSuccess: 'Crop has been Added Successfully',
      rightRack: 'Choose The Right Rack location',
      usedRack: 'This Rack Is Aleady In Use',
      deleteRackSuccess: 'Successfully Deleted',
      realNoData: 'No Data Available',
      selectCropNm: 'Please Select Crop',
      yieldManagement: 'Yield Management',
      farmInfo: 'Farm Information',
      addPlan: 'Add Plan',
      editPlan: 'Edit Plan',
      asOfPlantDate: 'As Of Plant Date',
      allCrops: 'All Crops',
      asOfHarvestDate: 'As Of Harvest Date',
      rackLocation: 'Rack',
      currentYield: 'Yield/Plan(%)',
      deleteMessage: 'Are You Sure To Delete Yield Plan and Resistration?',
      deleteYield: 'Yield Resistration Has Been Deleted.',
      deleteYieldUnit: 'Are You Sure To Delete This Yield Registration?',
      rightValue: 'Please Enter The Values Correctly.',
      addYield: 'Yield Registration Has been Added.',
      editYield: 'Yield Registration Has been Changed.',
      selectController: 'Please Select The Controller First.',
      addYieldPlan: 'Yield Plan Has Been Added.',
      downloadYield: 'Are You Sure To Download Yield Registration Data?',
      edit: 'Edit',
      beforeSeeding: 'It cannot be harvested so quickly.',
      farmName: 'SmartFarm Name',
      yieldData: 'Yield Data',
      excelDownloadComplete: 'Excel Download Complete',
      cropD1: 'Crop(',
      cropD2: ')Do you want to edit?',
      cropD3: ')has been editted',
      confirm: 'confirm',
},
  photoRegistration: {
    startYmd: 'Start Date',
    endYmd: 'End Date',
    oneMonth: '1month',
    sixMonth: '6month',
    oneYear: '1year',
    idx: 'Number',
    cropNm: 'Crop Name',
    photoDate: 'Photo Date',
    sowingDate: 'Sowing Date',
    connectNm: 'Connnect Name',
    locationDetail: 'Location Detail',
    photos: 'Farm Photos',
    desc: 'Description',
    locationPlaceholder:'ex. Building A, 2nd row, 3nd Column',
    descPlaceholder:'Write at least 8 characters',
    register: 'Photo Registration',
    noData: 'There is no registered photos.',
    reg: 'Register',
    edit:'Edit',
    cancel: 'Cancel',
    delete:'Delete',
    registerPhoto: 'Farm photo registration',
    editPhoto: 'Farm photo Edit',
    tableMode: 'Table Mode',
    galleryMode: 'Gallery Mode',
    error: 'An error occurred during upload.',
    and: 'and',
    sheet: 'sheet',
    invalidAll: 'Please enter all information.',
    invalidPhotos: 'Please attach a photo file.',
    cropImgAdded: 'Farm photos added.',
    cropImgEditted: 'Farm photos editted.',
    cropImgdeleteAsk: 'Are you sure you want to delete the farm photos?',
    cropImgdeleted: 'Farm photos deleted',
  },
  deviationStatus: {
    noDeviation: 'No Deviation',
    yesDeviation: 'Deviation Action Required',
    haveToAction: 'Have To Action(KakoTalk Notification -> Action)',
    temperature: 'Temperature',
    humidity : 'Humidity',
    waterTemp: 'Water Temperature',
    waterLevel : 'Water Level',
    waterPump : 'Water Pump(ab/c)',
    recieveData : 'Recieve Data',
    deviationStatus: 'Deviation Status',
    deviationTimeseries: 'Deviation Timeseries',
    urgentHigh: 'Urgent High',
    urgentLow: 'Urgent Low',
    notificationLog: 'Notification Log',
    date: 'Date',
    connectNm: 'Connect Name',
    envType: 'Env. Type',
    envValue: 'Env. Value',
    cancel: 'Cancel',
    deviationAction: 'Deviation Action',
    actionDate: 'Action Date',
    actionDesc: 'Action Description',
    pauseNotification: 'Pause Notification After Action',
    hour: 'hour',
    manageYn: 'Action manage',
    sendTarget: 'Notification type',
    selectDeviationPlz: 'Please choose a deviation.',
    actionSuccess: 'Action Success',
    plzInsertDesc: 'Please enter action description.',
    deviation: 'Deviation',
    action: 'action'
  },
  measurement: {
    measure: 'Measure',
    measurementInputPage: 'Enter environmental measurements',
    selectDate: 'Select Date',
    measureRegistered: 'The measurement value has been registered',
    register: 'Register',
    edit: 'Edit',
    delete: 'Delete',
    temperature: 'Temperature(°C)',
    controllerMeasured: 'Controller/Measured',
    humidity: 'Humidity(%)',
    waterTemperature: 'Water Temperature(°C)',
    co2: 'CO2(ppm)',
    eC: 'EC',
    pH: 'pH',
    close: 'Close',
    farmInformation: 'Farm Information',
    EnvManagement: 'Env. Management',
    controller: 'Controller',
    measureYmd: 'Measure Date',
    amPm: 'AM/PM',
    temperatureControllerMeasured: 'Temp.(°C) Contr./Meas.',
    humidityControllerMeasured: 'Humidity(%) Contr./Meas.',
    waterTemperatureControllerMeasured: 'Water Temp.(°C) Contr./Meas.',
    co2ControllerMeasured: 'CO2(ppm) Contr./Meas.',
    eCControllerMeasured: 'EC Contr./Meas.',
    pHControllerMeasured: 'pH Contr./Meas.',
    tooMuchValueDifferences: 'Too Much Value Differences, Please Check Controller and Measured Value Again.',
    measuredValuechanged: 'Measured Value Has Been Changed.',
    measuredValueDeleted: 'Measured Value Has Been Deleted.',
    measuredControllerValaueDeleted: 'Are You Sure to Delete Measured and Controller Value?',
    noData: 'No data available.',
    startYmd: 'Start Date',
    endYmd: 'End Date',
    am: 'AM',
    pm: 'PM',
    invalidConnect: 'Please select controller.',
    getCurrentValue: 'Get current value',
    connectNm: 'Controller Name',
    measureDate: 'Measure Date',
    alreadyEntered: 'There is already entered data. Would you like to overwrite it?',

    excelDownload: 'Download Excel',
    excelDownloadConfirm: 'Do you want to download measure data for that period?',
    excelDownloadComplete: 'Excel Download Complete',
    excelFileNm: 'env. Measure Data.xlsx',
    tempVal: 'Temp.(°C) Contr.',
    tempMeasure: 'Temp.(°C) Meas.',
    humidityVal: 'Humidity(%) Contr.',
    humidityMeasure: 'Humidity(%) Meas.',
    waterTempVal: 'Water Temp.(°C) Contr.',
    waterTempMeasure: 'Water Temp.(°C) Meas.',
    co2Val: 'CO2(ppm) Contr.',
    co2Measure: 'CO2(ppm) Meas.',
    ecVal: 'EC Contr.',
    ecMeasure: 'EC Meas.',
    pHVal: 'pH Contr.',
    pHMeasure: 'pH Meas.',
  },
    infoA: {
      farmAvgProductionPDay: 'Daily Prodouction',
      farmAvgProductionPWeek: 'Weekly Production',
      farmAvgProductionPMonth: 'Monthly Production',
      electricAvgUsage: 'Monthly Electricity Usage',
      waterAvgUsage: 'Monthly Water Usage',
      co2AvgUsage: 'Monthly CO₂ Usage',
      farmManagerName: 'Name',
      farmManagerPhoneNo: 'Phone Number',
      farmManagerEmail: 'Email',
      won: '￦',
  },
    infoB: {
      farmScale: 'Farm Information (Scale)',
      facilityInfo: 'Facility Information',
      farmLocation: 'Farm Distriction Name(Zone)',
      farmLocationGb: 'Farm Distriction',
      farmArea: 'Site Area',
      farmCultivateArea: 'Cultivation Area',
      envCtrlCount: 'Env. Controller Numbers',
      nutCtrlCount: 'Nutrition Controller Numbers',
      layerCount: 'Layers',
      rowCount: 'Rows',
      rackCount: 'Racks',
      bedCount: 'Beds',
      bedStandards: 'Standard Bed',
      seedRackCount: 'Seedling Racks ',
      transplantRackCount: 'Transplanting Racks',
      lastfeedRackCount: 'Planting Racks',
      rackTypes: 'Rack Types',
      potCount: 'Feeding Pots',
      pyeong: 'm²',
      units: 'unit(s)',
  },
    company: {
      name: 'Corporate Name',
      contactNumber: 'Contact Number',
      address: 'Address',
      detailedAddress: 'Address Detail',
      findAddress: 'Find Address',
      representative: 'Representative',
      date: 'Date',
      edit: 'Edit',
      cancel: 'Cancel',
      companyManagement: 'Company Management',
      editted: 'Company information has been modified.',
      editQuestion: 'Do you want to edit company information?',
      companyInvalidate: 'Please enter your company name.',
      addressInvalidate: 'Please enter your address.',
      dateInvalidate: 'Please enter the start date.'
    },
    scale: {
      title: 'Farm Info(Scale)',
      locationName: 'Location Name',
      locationClassification: 'Location Classification',
      siteArea: 'Site Area',
      cultivationArea: 'Cultivation Area',
      envControllerCount: 'Env. Controller Count',
      nutritionControllerCount: 'Nutrition Controller Count',
      num : 'ea'
    },
    statistics: {
        title: 'Farm Statistics',
        avgProductionPerDay: 'Avg Production Per Day',
        avgProductionPerWeek: 'Avg Production Per Week',
        avgProductionPerMonth: 'Avg Production Per Month',
        avgWaterUsagePerMonth: 'Avg Water Usage Per Month',
        avgElectricityUsagePerMonth: 'Avg Electricity Usage Per Month',
        avgCO2UsagePerMonth: 'Avg CO₂ Usage Per Month',
        won: "￦",
        edit: 'Edit',
        cancel: 'cancel',
        editMessage: 'Farm statistic information has been editted',
    },
    facility: {
      title: 'Farm Facility',
      layerCount: 'Layer Count',
      rowCount: 'Row Count',
      rackType: 'Rack Type',
      bedStandard: 'Bed Standard',
      bedCount: 'Bed Count',
      rackCount: 'Rack Count',
      seedRackCount: 'Seedling Rack Count',
      transplantRackCount: 'Transplanting Rack Count',
      lastFeedRackCount: 'Planting Rack Count',
      portCount: ' Pot Count',
      rackLocation: 'Rack Location',
    },
    manager: {
      title: 'Farm Manager',
      editTitle: 'Farm Manager Edit',
      manager: 'Manager',
      managerMobilePhone: 'Mobile Phone',
      managerEmail: 'Email',
      edit: 'Edit',
      cancel: 'Cancel',
      editMessage: 'The manager information has been corrected.'
    },
    floorPlan: 'Floor Plan',
    farmResources: {
      references: 'References',
      document: 'Document',
      video: 'video',
      etc: 'etc'
    },
    cultivationCrop: {
      title: 'Farm Cultivation Crop',
      numbering: 'Number',
      cropName: 'Crop Name',
      cultivationCropType: 'Cultivation Crop Type',
      cultivationEnvStandard: 'Cultivation Env. Standard',
      controllerConnection: 'Controller Connection',
      register: 'Register',
      total: 'Total',
      cropsCount: ' Crops',
      noData: 'No data available'
    },
    device: {
      title: 'Farm Device Info',
      numbering: 'Number',
      deviceClassification: 'Device Classification',
      deviceType: 'Device Type',
      subDeviceType: 'Sub Device Type',
      deviceName: 'Device Name',
      deviceStatus: 'Device Status',
      controllerConnection: 'Controller Connection',
      total: 'Total',
      deviceCount: ' Devices'
    },
    farmDetail: {
      companyNm: 'Company Name',
      smartFarmDetail: 'Farm Detail',
      farmName: 'Farm Name',
      farmCode: 'Farm Code',
      operatingState: 'Operating State',
      cultivateType: 'Cultivate Type',
    },
    farmPopUp: {
      basicInfoA: 'Basic Information A',
      basicInfoB: 'Basic Information B',
      controllerInfo: 'Controller Information',
      cropInfo: 'Crop Information',
      machineInfo: 'Machine Information',
    },
    controller: {
      title: 'Farm Controller Info',
      numbering: 'Number',
      controllerName: 'Controller Name',
      controllerCode: 'Controller Code',
      controllerType: 'Controller Type',
      cropEnvType: 'Cultivation Env. Type',
      culturalType: 'Cultivation Type',
      isInternetConnected: 'Is Internet Connected',
      systemId: 'System ID',
      setting: 'Setting',
      total: 'Total',
      controllerCount: ' Controllers',
      systemIdNotSet: 'The system ID is not set.',
    },
    timeseries: {
      startDate: 'Start Date',
      endDate: 'End Date',
      whole: 'Whole',
      temperature: 'Temperature',
      waterTemperature: 'Water temperature',
      humidity: 'Humidity',
      CO2 : 'CO₂',
      EC: 'EC',
      pH: 'pH',
      waterLevelSolenoid: 'Water Level Solenoid',
      waterLevelMain: 'Water Level Main',
      nutrientPump: 'Nutrient Solution Pump',
      waterLevel: 'Water Level',
      minLine: 'MinLine',
      maxLine: 'MaxLine',
      nminLine: 'Night MinLine',
      nmaxLine: 'Night MaxLine',
      limithigh: 'Limit High',
      limitlow: 'Limit Low',
      nlimithigh: 'Night Limit High',
      nlimitlow: 'Night Limit Low',
      targetLine: 'Target',
      noData: 'No data available',
      trendLine: 'Trend Line',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      downloadTimeseriesData: 'Are You Sure To Download Timeseries Data?',
      logIssue: 'Issue Collected Log',
      logIssueInfo: 'Drag: Zoom, Clike: Log',
      timeSeries: 'Time Series Data',
      farmNm: 'Farm Name',
      connectNm: 'Connnect Name',
      systemId: 'System ID',
      registrant: 'Registrant',
      registeredDate: 'Registration Date',
      envType: 'Env. Type',
      envValue: 'Env. Value',
      collectDate: 'Collection Date',
      issue: 'Issue',
      logSystemId: 'System ID',
      logCollectDate: 'Collection Date',
      logCollectType: 'Env. Type',
      logCollectData: 'Env. Value',
      comment: 'Comment',
      commentPlz: 'Please input a comment.',
      commentSuccess: 'A comment has been registered.',
      commentConfirm: 'Would you like to post a comment?',
      threeMonth: 'Only data within 3 months can be viewed.',
      noExcelData: "There is no data for that period.",
      excelDownloadComplete: 'Excel download complete',
      setEndDate: 'Please set the end date after the start date.',
      register : 'Register',
      cancel : 'Cancel',
      zeroVlaueDescription: '(When exceeding twice the number of limit or 0)',
      showOutlier: 'Show outliers'
    },
    alertLogTimeSeries: {
      startDate: 'Start Date',
      endDate: 'End Date',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      temperature: 'Temperature',
      humidity: 'Humidity',
      CO2 : 'CO₂',
      EC: 'EC',
      pH: 'pH',
      deviationCount: 'Deviation Count',
      management: 'Caution Level',
      abnormalAlarm: 'Abnormal Alarm Level',
      physiologicalDisorder: 'Physiological Disorder Level',
      noData: 'No data available',
      setEndDate: 'Please set the end date after the start date.',
      threeMonth: 'Only data within 3 months can be viewed.',
      thresholdcheck: 'Deviation standard information',
      threshold: {
        thresholdInfo: 'Deviation standard information',
        targetValue: 'Target Value',
        management: 'Management',
        abnormalAlarm: 'Abnormal Alarm',
        physiologicalDisorder:'Physiological Disorder',
        maximum: 'max',
        minimum: 'min',
        day: 'Day',
        night: 'Night',
        close: 'Close',
        temp: 'Temperature (°C)',
        hum: 'humidity (%)',
        seedling: 'Seedling',
        transplant: 'Transplant',
        lastfeed: 'Lastfeed'
      }
    },

    weeklyReport: {
      farmDataAnalysis: 'Farm Data Analysis',
      weeklyAnalysis: 'Weekly Report',
      endDateMessage: 'Please Set The End Date After The Start Date.',
      periodDateMessage: 'Please Select Data Within 3 Months.',

      cultivationMangementCommentDelete: 'Cultivation Management Comment Has Been Deleted.',
      error: 'Error Has Been Occured, Please Try Again',
      cultivationMangementCommentAsk: 'Are You Sure To Delete Cultivation Management Comment?',
      cultivationMangementCommentAddAsk: 'Are You Sure To Add Cultivation Management Comment?',
      cultivationMangementCommentAdded: 'Cultivation Management Comment Has Been Added Successfully.',
      selectController: 'No Controller Selected.',
      typecultivationMangementComment: 'Please Type Cultivation Management Comment.',

      farmStatusAnalysis: 'Farm Status Analysis',
      cultivateEnv: 'Cultivate Env.',

      diagnosticStandard: 'Diagnostic Standard',
      weight: 'Weight',
      farmEnvOutOfRangeStatus: 'Farm Env. Out of Range Status',
      standard: 'Standard',
      temperature: 'Temperature',
      humidity: 'Humidity',
      OutOfRangeNumbersPerDay: 'Out Of Range Numbers Per Day',
      noData: 'No Data Available',
      farmInformation: 'Farm Information',
      farmArea: 'Site Area',
      farmCultivateArea: 'Cultivation Area',
      envCtrlCount: 'Env. Controller Numbers',
      nutCtrlCount: 'Nutrition Controller Numbers',
      farmAvgProductionPDay: 'Daily Prodouction',
      waterAvgUsage: 'Monthly Water Usage',
      electricAvgUsage: 'Monthly Electricity Usage',
      co2AvgUsage: 'Monthly CO₂ Usage',
      layerCount: 'Layer Count',
      rackCount: 'Rack Count',
      potCount: 'Pot Count',
      farmCultivationMangementLog: 'Cultivation Management Log',
      cultivationMangementComment: 'Cultivation Management Comment',
      units: 'unit(s)',
      bedCount: 'Bed Count',
      won: '￦',
      total: 'Total',

      yield: 'Yield(Kg)',
      data: 'Data(%)',
      temperature2: 'Temperature(%)',
      humidity2: 'Humidity(%)',
      date: 'Date',
      dataNumber: 'Number of Data',
      farmStatus: 'Farm Status',
      register: 'Register',
      systemId: 'System ID',
      collectDate: 'Collect Date',
      cancel: 'Cancel',
      commentEditted: 'Comment Editted',
      commentConfirm: 'Would you like to post a comment?',
      connectNotSelected: 'No controller is selected.',
      commentplz: 'please enter a comment.',
      compare: 'Compare',
      thresholdcheck: 'Deviation standard information',
      threshold: {
        thresholdInfo: 'Deviation standard information',
        targetValue: 'Target Value',
        management: 'Management',
        abnormalAlarm: 'Abnormal Alarm',
        physiologicalDisorder:'Physiological Disorder',
        maximum: 'max',
        minimum: 'min',
        day: 'Day',
        night: 'Night',
        close: 'Close',
        temp: 'Temperature (°C)',
        hum: 'humidity (%)',
        seedling: 'Seedling',
        transplant: 'Transplant',
        lastfeed: 'Lastfeed'
      },
      totalWeight: 'Total weight'
    },
    //culturalCrop 하고 많이 겹침
    cultivationCropMangement: {
      title: 'Farm Cultivate Crops',
      numbering: 'Index',
      cropName: 'Crop Name',
      cropNumber: 'Crop Number',
      registerCrop: 'Register Crop',
      selectCultivationCropType: 'Select Cultivation Crop Type',
      selectCultivationCrop: 'Select Cultivation Crop',
      cultivationCropType: 'Cultivation Crop Type',
      cultivationEnvStandard: 'Cultivation Standard Env.',
      cropShipmentPerMonth: 'Crop Shipment Per Month (kg)',
      detail: 'Detail',
      total: 'Total',
      count: '',
      register: 'Register',
      save: 'Save',
      remove: 'Remove',
      growthPeriod: 'Growth Period',
      all: 'All',

      close: 'Close',
      noCropResults: 'There Are No Crop Results',
      cropAddSuccess: 'Crop has been Added Successfully',
      cropAddError: 'Error Has Been Occured, Please Try Again',
      addCropToFarm: 'Are You Sure To Add This Crop To The Farm?',
      checkCrop: 'Please Select Crop To Add.',
      standardWeight: 'Std. Weigt per Head',
      monthAvgOutput: 'Monthly Plan(kg)',

      edit: 'Edit',
      cancel: 'Cancel',
      cropNm: 'Crop Name',
      nutrientTpye: 'Nutrient Type',
      cultivateCropType: 'Cultivate Crop Type',
      cultivateEnvStandards: 'Env. Standards',
      day: 'Day',
      titration:'Titration',
      red: 'Red',
      bule: 'Blue',
      green: 'Green',
      white: 'White',
      seed1: 'Seed#1',
      seed2: 'Seed#2',
      connect: 'Connection',
      informationByCropSeason: 'Information By Crop Season',
      seedling: 'Seedling',
      transplant: 'Transplant',
      lastfeedDay: 'LastfeedDay',
      dayCount: 'Day Count',
      temp: 'Temp',
      tempDay: '(Temp)Day',
      tempNight: '(Temp)Night',
      waterTemp: 'WaterTemp',
      waterTempDay: '(WaterTemp)Day',
      waterTempNight: '(WaterTemp)Night',
      humidity: 'Humidity',
      humidityDay: '(Humidity)Day',
      humidityNight: '(Humidity)Night',
      light: 'Light',
      lightIntensity: 'Light Intensity',
      lightColor: 'Light Color',
      lightTime: 'Light Time',

      cropD1: 'Crop(',
      cropD2: ')has been deleted',
      cropD3: ') Delete?',
      cropD4: ')has been aditted',
      error: 'Error Has Been Occured, Please Try Again',
    },
    farmSpecificityInformation: {
      farmSpecificityInformation: 'Farm Specificity Information',
      register: 'Register',
      numbering: 'Numbering',
      specificy: 'Specificity',
      description: 'Description',
      tag: 'Tag',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
      tagPlaceholder: 'Please enter a tag and press enter',
      specificityPlaceholder: 'Please enter specificity information',
      deleted: 'The specificity information has been deleted',
      deleteQuestion: "Are you sure you want to delete the specificity information?",
      invalidvalue: "Please enter the values correctly",
      saved: 'Specificity information saved',
      edited: 'Specificity information has been corrected'
    },
    farmCalendar:{
      taskRegister: 'Register Schedule',
      taskEdit: 'Edit Schedule',
      category: 'Category',
      taskDesc: 'Schedule Desc',
      register: 'Register',
      edit: 'Edit',
      cancel: 'Cancel',
      delete: 'Delete',
      nutrientSolution: 'Nutreint Solution',
      airConditioner: 'Air Conditioner',
      electricity: 'Electricity',
      etc: 'Etc',
      invalidDesc: "Please enter the values correctly.",
      invalidDate: "Please check the date correctly.",
      registered: 'The schedule has been registered.',
      editted: 'The schedule has been editted.',
      deleted: 'The schedule has been deleted.',
      deleteAsk: 'Are you sure you want to delete this schedule?',

      author: 'Author',
      taskStartDate: 'Schedule Start',
      taskEndDate: 'Schedule End',
      connectNm: 'Connnect Name'
    }
  },
  claim: {
    all: 'All',
    requested: 'Requested',
    inProgress: 'In Progress',
    completed: 'Completed',
    confirmed: 'Confirmed',
    numbering: 'Number',
    registrant: 'Registrant',
    type: 'Type',
    claim: 'A/S',
    registeredDate: 'Register Date',
    progressStatus: 'Progress Status',
    register: 'Register',
    look: 'Detail',
    edit: 'Edit',
    noData: `Request Doesn't exist`,
    registerClaim: {
      registrant: 'Registrant',
      contact: 'Contact Number',
      email: 'Email',
      corporateName: 'Corporate Name',
      farmName: 'Farm Name',
      type: 'A/S Type',
      typePlaceholder: 'Select A/S type',
      'SW': 'SW',
      '설비': 'Facilities',
      '재배': 'Cultivation',
      '기타': 'Etc',
      title: 'A/S Title',
      titlePlaceholder: 'A/S title',
      content: 'A/S Content',
      contentPlaceholder: 'Detailed Content',
      attachment: 'Attachment',
      attachmentPlaceholder: 'Upload attachment',
      add: 'Add',
      register: 'Register',
      edit: 'Edit',
      back: 'Back',
      error: 'Error Has Been Occured, Please Try Again',

      fileConfirm: 'You can attach up to 5 attachments.',
      claimAdded: 'The claim has been registered.',
      claimConfirm: 'Are you sure you want to register a claim?',
      claimEditted: 'The claim has been corrected.',
      claimEditConfirm: 'Are you sure you want to edit the claim?',
      requestTitle: 'Please enter a request title.',
      requestDesc: 'Please enter a request description.',
      claimConfirmed: 'Claims have been verified.',
      claimConfirmAsk: 'Are you sure you want to confirm the claim?',
      confirm: 'Confirm',
    },
    detail: {
      whoIsInChargeOfProcess: 'Who Is In Charge Of',
      whoProcessedTheClaim: 'Who Processed The A/S',
      processContent: 'Detailed Content',
      processedDate: 'Processed Date',
      look: 'detail'
    }
  },
  faq: {
    search: 'Search',
    inquiry: 'Inquiry',
    searchPlaceholder: 'Search FAQs',
    customerService: 'Customer Service',
    partnershipInquiry: 'Partnership Inquiry',
    serviceIncidentInquiry: 'Service Error Inquiry',
    consultationRequest: 'Would you like to inquire about it?',
    registrant: 'Registrant',
    registeredDate: 'Date',
    coporateName: 'Company',
    farmName: 'Farm Name',
    inquiryContent: 'Contents',
    inquiryContentPlaceholder: 'Please fill the inquiry',
    inquiryAsk: '해당 내용으로 문의 하시겠습니까.',
    send: 'Send',
    useQuickFaq: 'Have Any Questions? Use Frequently Asked Questions',
    inquiryrequest: 'Inquiry Has Been Requested',
    error: 'Error Has Been Occured, Please Try Again',
  },
  notice: {
    numbering: 'Number',
    type: 'Notice Type',
    title: 'Title',
    registrant: 'Registrant',
    registeredDate: 'Registered Date',
    noData: `Notice doesn't exist`,
    look: 'look',
  },
  noticeView: {
    noticeDetail: 'Notice in Details',
    registrant: 'Registrant',
    registeredDate: 'Registered Date',
    title: 'Title',
    detail: 'Details',
    back: 'back',
    download: 'download',
},
  cultivationEnvControlSetting: {
    target: 'Setting Value',
    targetDay: 'Day',
    targetNight: 'Night',
    temperature: 'Temperature',
    humidity: 'Humidity',
    CO2: 'CO₂',
    pH: 'pH',
    EC: 'EC',
    light: 'Light',
    uv: 'UV Lamp',
    fan: 'Fan',
    airfan: 'Airfan',
    pump: 'Pump',
    management: 'Management',
    abnormalalert: 'Abnormal Alert',
    diff: 'Deviation',
    min: 'Min',
    max: 'Max',
    targetLine: 'Target',
    limitHigh: 'Limit High',
    limitLow: 'Limit Low',
    operationTime: 'Operation Time',
    interval: 'Interval',
    duration: 'Duration Time',
    startTime: 'Start Time',
    endTime: 'End Time',
    repeatCount: 'Repeat Count',
    noTimeSelected: 'No Time Selected',
    power: 'Power',
    maintenenceTime: 'Maintenence Time',
    alwaysPerHour: 'Always/h',
    halfAnHourPerHour: '30 minutes/h',
    edit: 'Edit',
    close: 'Close',
    maxDay: 'MaxLine (Day)',
    minNight: 'MinLine (Day)',
    min2: 'MinLine',
    max2: 'MaxLine',
    hour: 'hour',
    settingEditted: 'setting have been editted',
    settingEditConfirm: 'want to edit the settings in?',
    time: ['Time 1', 'Time 2', 'Time 3', 'Time 4', 'Time 5', 'Time 6']
  },
  dropdownNotification:{
    farmAlarm: 'Farm Alarm',
    totalAlarm: 'Total Alarm',
    unreadAlarm: 'Unread Alarm',
    noAlarm: 'No Alarm',
    noUnreadAlarm: 'No unread Alarm',
    gotoDeviationStatus: 'Go to Deviation Status',
    readAll: 'Read All',
    more: 'More',
    loading: 'Loading',
    refresh: 'Refresh'
  }
};