import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from "@/core/services/api.service";
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from 'perfect-scrollbar';
import ClipboardJS from 'clipboard';
import VueCryptojs from 'vue-cryptojs'
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/treeselect';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import HighchartsVue from 'highcharts-vue'
import KnobControl from 'vue-knob-control'
import vueDebounce from 'vue-debounce'
import VueSweetalert2 from 'vue-sweetalert2'
import device from "vue-device-detector"
import 'sweetalert2/dist/sweetalert2.min.css';
// API service init
ApiService.init();

Vue.config.productionTip = false;
Vue.use(device)
Vue.use(VueCryptojs)
Vue.use(HighchartsVue)
Vue.use(KnobControl)
Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup']
})
Vue.use(VueSweetalert2)

window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
