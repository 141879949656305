import { uuidv4 } from '@/core/services/funcs';

const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_TOKEN_KEY = "id_refresh_token";
const DEVICE_UUID_KEY = 'device_uuid';
const LOGIN_STAY_YN_KEY = 'login_stay_yn';

function setCookie(cookie_name, value, days) {
  const exDate = new Date();
  exDate.setDate(exDate.getDate() + days);
  const cookie_value = escape(value) + ((days == null) ? '' : '; expires=' + exDate.toUTCString());
  document.cookie = cookie_name + '=' + cookie_value;
}

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const getDeviceUuid = () => {
  return window.localStorage.getItem(DEVICE_UUID_KEY) || uuidv4();
}

export const getLoginStayYn = () => {
  return window.localStorage.getItem(LOGIN_STAY_YN_KEY);
}

export const saveToken = (token, refreshToken, loginStayYn) => {
  // 임시 처리
  setCookie("Farm-Auth-Token", token, 1);
  // setCookie("login-stay-yn", loginStayYn, 1);
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, refreshToken);
  window.localStorage.setItem(LOGIN_STAY_YN_KEY, loginStayYn);
};

export const saveDeviceUuid = uuid => {
  window.localStorage.setItem(DEVICE_UUID_KEY, uuid);
};

export const destroyToken = () => {
  setCookie("Farm-Auth-Token", null, 0);
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

export default { getToken, getRefreshToken, saveToken, destroyToken, getDeviceUuid, saveDeviceUuid, getLoginStayYn };
