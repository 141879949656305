export const API_KEY = "vevvfzy8kbaqqe1kn3dpitepqe9cjoka"
export const ANDROID_API_KEY = 'r1fmk8lqnr5ksl0moxst8n5d1ji7rxxu'
export const IOS_API_KEY = '1jelh1opedjkxr1cuuxvgqjqbs4ul8zo'


export const ACT_GET_COMMON_CODES = "getCommonCodes"

export const ACT_GET_MY_FARMS = 'getMyFarms'
export const ACT_GET_MY_FARM = 'getMyFarm'
export const ACT_GET_MY_FARM_CLAIMS = 'getMyFarmClaims'
export const ACT_GET_MY_FARM_NOTICES = 'getMyFarmNotices'
export const ACT_GET_FARM_ALARMS = 'getFarmAlarms'
export const ACT_GET_MY_CONNECTS = 'getMyConnects'
export const ACT_GET_CONNECT_SET = 'getConnectSet'
export const ACT_INSERT_CONNECT_SET = 'insertConnectSet'
export const ACT_GET_MY_CROPS = 'getMyCrops'
export const ACT_INSERT_MY_CROPS = 'insertMyCrops'
export const ACT_UPDATE_MY_CROP = 'updateMyCropProd'
export const ACT_DELETE_MY_CROP = 'deleteMyCrop'

export const ACT_GET_MY_FARM_RACK = 'getMyFarmRack'
export const ACT_INSERT_MY_FARM_RACK = 'insertMyFarmRack'
export const ACT_UPDATE_MY_FARM_RACK = 'updateMyFarmRack'
export const ACT_DELETE_MY_FARM_RACK = 'deleteMyFarmRack'


export const ACT_GET_CLAIM = 'getClaim'
export const ACT_GET_MY_CLAIMS = 'getMyClaims'
export const ACT_INSERT_MY_CLAIMS = 'insertMyClaims'
export const ACT_UPDATE_MY_CLAIMS = 'updateMyClaims'
export const ACT_UPDATE_MY_CLAIMS_CONFIRM = 'updateMyClaimConfirm'

export const ACT_GET_NOTICE = 'getNotice'
export const ACT_GET_MY_NOTICES = 'getMyNotices'
export const ACT_GET_CROPS = 'getCrops'
export const ACT_GET_STATUS_LOGS_STATICS = 'getStatusLogsStatics'
export const ACT_GET_ALERT_LOGS_STATICS = 'getAlertLogsStatics'
export const ACT_GET_WEATHER = 'getWeather'
export const ACT_GET_WEATHER_FARM = 'getWeatherFarm'

export const ACT_GET_CROP_PRICES = 'getCropPrices'
export const ACT_GET_KAMIS_DAY = 'getKamisDay'
export const ACT_GET_KAMIS_ITEMS = 'getKamisItems'

export const ACT_INSERT_CUSTOMER = 'insertCustomer'
export const ACT_GET_FAQS = 'getFaqs'

export const ACT_GET_FARM_CONNECT = 'getFarmsConnect'

export const MUT_SET_FARM_ID = 'setFarmId'
export const MUT_SET_FARM_DETAIL = 'setFarmDetail'

export const RELEASE_CONNECT_ALARM = 'releaseConnectAlarm'
export const GET_WEEKLY_STATISTICS = 'getWeeklyStatistics'
export const GET_WEEKLY_COMMENTS = 'getWeeklyComments'
export const ACT_INSERT_MY_WEEKLY_COMMENT = 'insertMyWeeklyComments'
export const ACT_GET_COMPARE_FARM = 'getCompareFarm'
export const ACT_GET_COMPARE_CONNECTS = 'getCompareConnects'
export const ACT_DELETE_MY_WEEKLY_COMMENT = 'deleteWeeklyComment'

export const ACT_STOP_ALARM = 'updateStopAlarm'

export const ACT_GET_NUTRIENT_SOLUTIONS ='getNutrientSolutions'

export const ACT_GET_STATUS_MEASURE = 'getStatusMeasure'
export const ACT_GET_STATUS_MEASURE_ONE = 'getStatusMeasureOne'
export const ACT_INSERT_STATUS_MEASURE = 'insertStatusMeasure'
export const ACT_UPDATE_STATUS_MEASURE = 'updateStatusMeasure'
export const ACT_DELETE_STATUS_MEASURE = 'deleteStatusMeasure'

export const ACT_GET_MY_FARM_YIELD_PLAN = 'getMyFarmYieldPlan'
export const ACT_INSERT_MY_FARM_YIELD_PLAN = 'insertMyFarmYieldPlan'
export const ACT_UPDATE_MY_FARM_YIELD_PLAN = 'updateMyFarmYieldPlan'
export const ACT_DELETE_MY_FARM_YIELD_PLAN = 'deleteMyFarmYieldPlan'
export const ACT_GET_MY_FARM_YIELD = 'getMyFarmYield'
export const ACT_INSERT_MY_FARM_YIELD = 'insertMyFarmYield'
export const ACT_UPDATE_MY_FARM_YIELD = 'updateMyFarmYield'
export const ACT_DELETE_MY_FARM_YIELD = 'deleteMyFarmYield'
export const ACT_GET_MY_FARM_TOTAL_YIELD = 'getMyFarmTotalYield'

export const ACT_GET_COMPANY = 'getCompany'
export const ACT_UPDATE_COMPANY = 'updateCompany'

export const ACT_GET_CONNECT_COMMENTS = 'getConnectComments'
export const ACT_INSERT_CONNECT_COMMENT = 'insertConnectComment'

export const ACT_INSERT_SPECIFICITY_INFO = 'insertSpecificityInfo'
export const ACT_UPDATE_SPECIFICITY_INFO = 'updateSpecificityInfo'
export const ACT_DELETE_SPECIFICITY_INFO = 'deleteSpecificityInfo'

export const ACT_UPDATE_MANAGER = 'updateManager'

export const ACT_GET_TASKS = 'getTasks'
export const ACT_INSERT_TASK = 'insertTask'
export const ACT_UPDATE_TASK = 'updateTask'
export const ACT_DELETE_TASK = 'deleteTask'
export const ACT_GET_TASK_CATEGORY_LIST = 'getTaskCategoryList'

export const ACT_GET_STATUS_LOGS = 'getStatusLogs'
export const ACT_GET_LAST_STATUS_LOG = 'getLastStatusLog'

export const ACT_UPDATE_STATISTICS = 'updateStatistics'

export const ACT_FARM_TOTAL_STATICS = 'getFarmTotalStatics'
export const ACT_GET_APP_PUSH = 'getAppPush'
export const ACT_UPDATE_APP_PUSH_ST = 'updateAppPushSt'
export const ACT_UPDATE_ALL_APP_PUSH = 'updateAllAppPush'

export const ACT_GET_FARM_THRESHOLD = 'getFarmThreshold'

export const ACT_DELETE_FILE = 'deleteFile'

export const ACT_GET_CROP_IMAGE = 'getCropImage'
export const ACT_INSERT_CROP_IMAGE = 'insertCropImage'
export const ACT_UPDATE_CROP_IMAGE = 'updateCropImage'
export const ACT_DELETE_CROP_IMAGE = 'deleteCropImage'

export const ACT_GET_DEVIATION_STATUS = 'getDeviationStatus'
export const ACT_GET_NOTIFICATION_DEVIATION = 'getNotificationDeviation'
export const ACT_GET_ALARM_MANAGEMENT = 'getAlarmManagement'
export const ACT_GET_NOTIFICATION_DETAIL = 'getNotificationDetail'
export const ACT_INSERT_NOTIFICATION_ACTION = 'insertNotification'

export const ACT_GET_MEMBERS = 'getMembers'

export const ACT_SEND_ALARM_TEST = 'sendAlarmTest'

export const ACT_GET_RENEWAL_SET = 'getRenewalSet'
export const ACT_UPDATE_RENEWAL_SET = 'updateRenewalSet'
