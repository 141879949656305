// import { nSQL } from '@nano-sql/core'
// import { getMode } from "@nano-sql/adapter-sqlite-cordova"
// import axios from 'axios'
// import store from '@/store'
import i18nService from "@/core/services/i18n.service.js";
import i18n from '@/core/plugins/vue-i18n.js';


const regexp = /^[0-9]*$/

// const setDayStr = function (tm) {
//   const str = tm.yyyy + '년 ' + tm.mm + '월 ' + tm.di + '일 ' + KOR_WEEK_SHORT_NAMES[tm.week] + '요일'
//   if (tm.daystr !== str) tm.daystr = str
//   let tmstr = ''
//   if (tm.hh < 12) {
//     if (tm.hh === 0) tmstr = 'AM 12' + ':'
//     else if (tm.hh < 10) { tmstr = 'AM 0' + tm.hh + ':' } else { tmstr = 'AM ' + tm.hh + ':' }
//   } else {
//     if (tm.hh > 12) {
//       const h = tm.hh - 12
//       if (h < 10) { tmstr = 'PM 0' + h + ':' } else { tmstr = 'PM ' + h + ':' }
//     } else {
//       tmstr = 'PM 12:'
//     }
//   }
//   tmstr += ((tm.mi < 10) ? '0' + tm.mi : tm.mi)
//   if (tm.tmstr != tmstr) tm.tmstr = tmstr
// }

// export const checkDayTime = function (tm) {
//   const dt = new Date()
//   const yyyy = dt.getFullYear()
//   const mm = dt.getMonth() + 1;
//   const di = dt.getDate();
//   const week = dt.getDay();
//   const hh = dt.getHours();
//   const mi = dt.getMinutes();
//   if (tm.yyyy !== yyyy) tm.yyyy = yyyy
//   if (tm.mm !== mm) tm.mm = mm
//   if (tm.di !== di) tm.di = di
//   if (tm.week !== week) tm.week = week
//   if (tm.hh !== hh) tm.hh = hh
//   if (tm.mi !== mi) tm.mi = mi
//   setDayStr(tm)
// }

// export function dateStr (value, delimiter = '-') {
//   const mm = value.getMonth() + 1
//   const dd = value.getDate()
//   return [value.getFullYear(),
//   (`0${mm}`).slice(-2),
//   (`0${dd}`).slice(-2),
//   ].join(delimiter)
// }

// export function dateMinStr(value, delimiter='') {
//   const mm = value.getMonth() + 1
//   const dd = value.getDate()
//   const hh = value.getHours()
//   const mi = value.getMinutes()
//   return [value.getFullYear(),
//   (`0${mm}`).slice(-2),
//   (`0${dd}`).slice(-2),
//   (`0${hh}`).slice(-2),
//   (`0${mi}`).slice(-2),
//   ].join(delimiter)
// }


const weeks = ['일', '월', '화', '수', '목', '금', '토']
const weeks_en = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const month_en = ['January', 'Fabruary', 'March', 'Aprill', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export function timestampToDate (timestamp, format) {
  const lang =i18nService.getActiveLanguage()
  const d = new Date(timestamp);	// Convert the passed timestamp to milliseconds
  let yyyy = d.getFullYear(),
      mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
      dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
      hh = d.getHours(),
      h = hh,
      min = ('0' + d.getMinutes()).slice(-2),		// Add leading 0.
      ampm = 'AM', time;
  if (lang == 'ko'){

    if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
    } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
    } else if (hh === 0) {
      h = 12;
    }

    if(format === 'yyyy.MM.dd'){
      time = yyyy + '.' + mm + '.' + dd;
    }else if(format === 'yyyy-MM-dd'){
      time = yyyy + '-' + mm + '-' + dd;
    }else if(format === 'yyyyMMdd'){
      time = yyyy + '' + mm + '' + dd;
    }else if(format === 'yyyyMMdd hh:mm'){
      time = yyyy + '' + mm + '' + dd + ' ' + hh + ':' + min;
    }else if(format === 'yyyy.MM.dd hh:mm'){
      time = yyyy + '.' + mm + '.' + dd + ' ' + hh + ':' + min;
    }else if(format === 'yyyy/MM/dd hh:mm:00'){
      time = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + min + ':00';
    }else if(format === 'hh:mm'){
      time = hh + ':' + min;
    }else if(format === 'hhmm'){
      time = hh + '' + min;
    }else if(format === 'hh:mm(A)'){
      time = h + ':' + min + '(' + ampm + ')';
    }else {
      time = yyyy + '년 ' + mm + '월 ' + dd + '일 (' + weeks[d.getDay()]  + ') ' + h + ':' + min + '(' + ampm + ')';
    }
  } else{


    if (hh > 12) {
      h = hh - 12;
      ampm = 'PM';
    } else if (hh === 12) {
      h = 12;
      ampm = 'PM';
    } else if (hh === 0) {
      h = 12;
    }

    if(format === 'yyyy.MM.dd'){
      time = yyyy + '.' + mm + '.' + dd;
    }else if(format === 'yyyy-MM-dd'){
      time = yyyy + '-' + mm + '-' + dd;
    }else if(format === 'yyyyMMdd'){
      time = yyyy + '' + mm + '' + dd;
    }else if(format === 'yyyyMMdd hh:mm'){
      time = yyyy + '' + mm + '' + dd + ' ' + hh + ':' + min;
    }else if(format === 'yyyy.MM.dd hh:mm'){
      time = yyyy + '.' + mm + '.' + dd + ' ' + hh + ':' + min;
    }else if(format === 'yyyy/MM/dd hh:mm:00'){
      time = yyyy + '/' + mm + '/' + dd + ' ' + hh + ':' + min +':00';
    }else if(format === 'hh:mm'){
      time = hh + ':' + min;
    }else if(format === 'hhmm'){
      time = hh + '' + min;
    }else if(format === 'hh:mm(A)'){
      time = h + ':' + min + '(' + ampm + ')';
    }else {
      time = weeks_en[d.getDay()]  + ', ' + month_en[d.getMonth()] + ' ' + dd + ', ' + yyyy + ' ' + h + ':' + min + '(' + ampm + ')';
    }
  }
  return time;
}

export function todayToDate (format) {
  const curr = new Date()
  const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);
  const offset = 1000 * 60 * 60 * 9
  const koreaNow = new Date(utc + offset)
  return timestampToDate(koreaNow.getTime(), format);
}

export function utcToDate (date, format) {
  const curr = new Date(date)
  const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);
  const offset = 1000 * 60 * 60 * 9
  const koreaNow = new Date(utc + offset)
  return timestampToDate(koreaNow.getTime(), format);
}

export function dateFormat (value, delimiter = '-', isTime) {
  if(value && value.length === 8){
    return [value.substr(0, 4),
      value.substr(4, 2),
      value.substr(6, 2),
    ].join(delimiter)
  }else if(value && value.length === 12){
    let result = [value.substr(0, 4),value.substr(4, 2),value.substr(6, 2),].join(delimiter)
    if(isTime){
      result += (' ' + value.substr(8, 2) + ':' + value.substr(10, 2))
    }
    return result
  }
  return null;
}

export const  uuidv4 = function () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

// export const isSuccess = (resp) => {
//   if (resp.data.status) {
//     if (resp.data.status.code === 200)
//       return true
//   } else if (resp.data.result) {
//     if (resp.data.result.number === 200)
//       return true
//   }
//   return false
// }
export function dateAddDel(beforeDate, addNumber, type) {
  if (!beforeDate){
    return '';
  }
  // 사용예시 dateAddDel('2019-06-22',2,'d'); // 2019-06-24
  let d;
  let yy = parseInt(beforeDate.substr(0, 4), 10);
  let mm = parseInt(beforeDate.substr(5, 2), 10);
  let dd = parseInt(beforeDate.substr(8), 10);

  if (type == "d") {    //일
  d = new Date(yy, mm - 1, dd + addNumber);
  }
  else if (type == "m") {   //월
  d = new Date(yy, mm - 1 + addNumber, dd);
  }
  else if (type == "y") {   //년
  d = new Date(yy + addNumber, mm - 1, dd);
  }

  yy = d.getFullYear();// 19를 2019로 변경
  mm = d.getMonth() + 1; mm = (mm < 10) ? '0' + mm : mm; //월 변경  +1 하는 이유는 자바스크립트에서 0이 1월이라
  dd = d.getDate(); dd = (dd < 10) ? '0' + dd : dd;//10일 이전이면 숫자 자릿수 맞추기 위함

  // let week = ['일', '월', '화', '수', '목', '금', '토'];
  // let dayOfWeek = week[d.getDay()];

  return '' + yy + '-' +  mm  + '-' + dd ;//+ ' ('+ dayOfWeek + ')'
}


export const isSuccess = (resp) => {
  return !!(resp && resp.result && resp.result.number === 200);
}

export const lengthCheck = (resp) => {
  return !!(resp && resp.items && resp.items.length > 0);

}

export const getItems = (resp) => {
  return resp.items;
}

export const getItem = (resp) => {
  return resp.items[0];
}

export const getPaging = (resp) => {
  if(resp && resp.paging) return resp.paging;
  return {
    pageNo: 1,
    pageSize: 10,
    totalCount: 0,
    hasMore: false
  }

}
export const getResult = (resp) => {
  if(resp && resp.result) return resp.result;
  return {
    number: 500,
    code: 'ERROR',
    message: '오류가 발생했습니다.'
  };
}

export const phoneFormat = (phoneNo) => {
  const phoneArr = [];
  // 모든 자릿수가 숫자일 경우
  if(regexp.test(phoneNo)){
    if(phoneNo.startsWith('02')){
      phoneArr.push(phoneNo.substr(0, 2))
      if(phoneNo.length === 9){
        phoneArr.push(phoneNo.substr(2, 3))
        phoneArr.push(phoneNo.substr(5, 4))
      }else{
        phoneArr.push(phoneNo.substr(2, 4))
        phoneArr.push(phoneNo.substr(6, 4))
      }
    }else{
      phoneArr.push(phoneNo.substr(0, 3))
      if(phoneNo.length === 10){
        phoneArr.push(phoneNo.substr(3, 3))
        phoneArr.push(phoneNo.substr(6, 4))
      }else{
        phoneArr.push(phoneNo.substr(3, 4))
        phoneArr.push(phoneNo.substr(7, 4))
      }
    }
  }
  return phoneArr.join('-')
}

export function hashToObj(hash) {
  if(!hash) return null
  if(hash.startsWith('#')) hash = hash.substr(1)
  const obj = {}
  const params = hash.split('&')

  params.forEach(param => {
    const target = param.split('=')
    if(target.length === 2){
      obj[target[0]] = (target[0] === 'pageNo' ? parseInt(target[1]) : target[1])
    }
  })
  return obj
}

export function objToHash(obj) {
  if(!obj) return ''
  let hash = ''
  for(let key in obj){
    hash += (key + '=' + obj[key] + '&')
  }
  if(hash.endsWith('&')) hash = hash.substr(0, hash.length -1)
  return hash
}

export function syncObj(target, obj){
  for(let key in obj){
    if(key === 'pageNo' && target.paging) target.paging[key] = obj[key]
    else target[key] = obj[key]
  }
}

export function syncObj2(target, obj){
  for(let key in target){
    if(obj[key] !== undefined) target[key] = obj[key]
  }
}

export function syncObj3(target, obj){
  for(let key in target){
    obj[key] = target[key]
  }
}

export function getOptions(items, initValue, notDisabled){
  const options = []
  if(initValue){
    options.push({
      value: '',
      text: initValue,
      disabled: !notDisabled
    })
  }

  items.forEach(item => {
    if(item.masterYn === 'N'){
      options.push({
        value: item.dcd,
        text: item.l10nKoDcdVal
      })
    }
  })
  return options
}

export function getValueWithDefault(value, defaultValue){
  if(value) return value;
  return defaultValue || '-';
}

export function getValueWithUnit(value, unit, isComma){
  if(value) {
    if(isComma) return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' ' + unit;
    return value + ' ' + unit;
  }
  return '-';
}

export function getPhoneFormatValue(phoneNo){
  if(phoneNo) {
    const phoneStr = phoneFormat(phoneNo);
    if(phoneStr) return phoneFormat(phoneNo);
  }
  return '-'
}

export function getValueWithComma(value){
  if(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return '-';
}

export function collectionsCheck(items, seq){
  return !!(items && items.length && items[0][seq]);

}

export function pagingSequence(paging, idx){
  if(paging){
    return paging.totalCount - ((paging.pageNo - 1) * paging.pageSize) - idx
  }
  return '-'
}

export function checkOnlyNumber(target){
  return regexp.test(target);
}

export function getHighchartYaxisMinMax(series, min, max) {
  const yAxisMinMax = series.reduce((acc, cur) => {
    return {
      min: Math.min(acc.min, cur[1]),
      max: Math.max(acc.max, cur[1])
    }
  }, { min, max })

  return yAxisMinMax
}

function refineHighchartDatetime(datetime) {
  // python서버등에서 timezone문제 때문에 프론트에서 임시로 수정
  const fixTimezoneHour = 9;

  let refinedDatetime = new Date(datetime)
  refinedDatetime.setHours(refinedDatetime.getHours() + fixTimezoneHour)

  return refinedDatetime
}

// Highchart의 그래프에서 xAxis의 시간값과 제대로 매칭되기 위해서 변환
export function getRefinedHighchartSeriesData(series) {
  const refinedSeriesData = series.map(data => {
    const dataTimestamp = refineHighchartDatetime(data[0])

    return [dataTimestamp.getTime(), data[1]]
  })

  return refinedSeriesData
}

export function lan(){
  if(Object.keys(i18n._localeChainCache)[0] == 'en'){
    return 'en'
  }
  return 'ko'
}

export function getCookie(cname) {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}