import Vue from 'vue';
import JwtService from '@/core/services/jwt.service';
import ApiService from '@/core/services/api.service';
import {getPhoneFormatValue} from '@/core/services/funcs';
import {API_KEY} from '@/core/services/variable';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const VERIFY = 'verify'
export const REFRESH_TOKEN = 'refreshToken'

export const GET_MY_PROFILE = 'getMyProfile';
export const UPDATE_MY_PROFILE = 'updateMyProfile'

export const UPDATE_CONFIRM_SIGNUP = 'updateConfirmSignup'
export const GET_MEMBER_INFO = 'getMemberInfo'
export const SEND_EMAIL_PWD_RESET = 'sendEmailPwdReset'
export const CONFIRM_PWD_RESET = 'confirmPwdReset'
export const UPDATE_PWD_RESET = 'updatePwdReset'

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_PROFILE = 'setMyProfile';
export const SET_DEVICE = 'setDevice';
export const SET_APIKEY = 'setApikey';

export const SET_PUSH_ITEM = 'setPushItem';
export const SET_PUSH_INFO = 'setPushInfo';
export const REGISTER_DEVICE_TOKEN = 'registerDeviceToken';

export const ACT_DELETE_MEMBER = 'deleteMember';


const state = {
  axios: null,
  errors: null,
  mno: 0,
  profile: {},
  isAuthenticated: !!JwtService.getToken(),
  apikey: API_KEY,
  apiUrl: ApiService.apiUrl(),

  loginStayYn: JwtService.getLoginStayYn(),
  isMobile: false,
  device: {},
  pushId: null,
  pushType: null,
  pushItem: null,

};

const getters = {
  isMobile(state) {
    return state.isMobile;
  },
  loginStayYn(state){
    return state.loginStayYn
  },
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  myMno(state) {
    return state.mno;
  },
  myId(state){
    if(state.profile) return state.profile.memberId;
    return '-'
  },
  myNm(state){
    if(state.profile) return state.profile.memberNm;
    return '-'
  },
  myEmail(state){
    if(state.profile) return state.profile.memberEmail;
    return '-'
  },
  myPhoneNo(state){
    if(state.profile) return getPhoneFormatValue(state.profile.phoneNo);
    return '-'
  }
};

const actions = {
  [LOGIN](context, {id, password, stayYn}) {
    return new Promise((resolve, reject) => {
      ApiService.post("/web/v1/auth-token", {
        memberId: id,
        memberPwd: Vue.CryptoJS.SHA256(password).toString(Vue.CryptoJS.enc.Hex),
        loginStayYn: stayYn,
        mobileYn: 'Y'
      })
      .then(response => resolve(response))
      .catch( error => reject(error));
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, {id, name, password, email, phoneNo}) {
    return new Promise((resolve, reject) => {
      ApiService.post("/web/v1/member/signup", {
        memberId: id,
        memberNm: name,
        memberPwd: Vue.CryptoJS.SHA256(password).toString(Vue.CryptoJS.enc.Hex),
        memberEmail: email,
        phoneNo: phoneNo
      })
      .then(response => resolve(response))
      .catch( error => reject(error));
    });
  },
  [GET_MY_PROFILE]() {
    return new Promise((resolve, reject) => {
      ApiService.get("/web/v1/my/profile", null)
      .then(response => resolve(response))
      .catch( error => reject(error));
    });
  },
  [VERIFY](context, {password}){
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/member/verify', {
        memberPwd: Vue.CryptoJS.SHA256(password).toString(Vue.CryptoJS.enc.Hex),
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [UPDATE_MY_PROFILE](context, {name, password, email, phoneNo}){
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/my/member', {
        memberNm: name,
        memberPwd: Vue.CryptoJS.SHA256(password).toString(Vue.CryptoJS.enc.Hex),
        memberEmail: email,
        phoneNo: phoneNo
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [REGISTER_DEVICE_TOKEN](context, {pushId, pushType}){
    context.commit(SET_PUSH_INFO, {pushId: pushId, pushType: pushType});
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/booting/notification/device', {
        deviceToken: pushId
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [UPDATE_CONFIRM_SIGNUP](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/member/confirm-signup', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [GET_MEMBER_INFO](context, Id) {
    return new Promise((resolve, reject) => {
      ApiService.get('/web/v1/member/'+ Id)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [SEND_EMAIL_PWD_RESET](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post('/web/v1/member/token/pwd-reset', param)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [CONFIRM_PWD_RESET](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/member/token/pwd-reset', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [UPDATE_PWD_RESET](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put('/web/v1/member/pwd-reset', params)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [ACT_DELETE_MEMBER](context, mno) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/web/v1/member/' + mno)
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  },
  [REFRESH_TOKEN](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/web/v1/refresh-token", {
        loginStayYn: 'Y',
        mobileYn: 'Y'
      }, )
      .then(response => resolve(response))
      .catch( error => reject(error));
    });
  },
};

const mutations = {
  [SET_AUTH](state, data) {
    // 로그인 성공
    JwtService.saveToken(data.authToken, data.refreshToken, data.loginStayYn);
    JwtService.saveDeviceUuid(data.uuid);
    state.isAuthenticated = true;
    state.loginStayYn = data.loginStayYn;
    state.mno = data.mno;
  },
  [PURGE_AUTH](state) {
    JwtService.destroyToken();
    state.mno = 0
    state.profile = null;
    state.isAuthenticated = false;
  },
  [SET_PROFILE](state, profile) {
    state.profile = profile;
    state.mno = profile.mno;
  },
  [SET_DEVICE](state, device){
    state.device = device;
  },
  [SET_APIKEY](state, apikey){
    state.apikey = apikey;
    state.isMobile = true;
  },
  [SET_PUSH_ITEM](state, pushItem){
    state.pushItem = pushItem;
  },
  [SET_PUSH_INFO](state, {pushId, pushType}){
    state.pushId =  pushId;
    state.pushType = pushType;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
